const initialState = {
  count: 0,
  all: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PUSH":
      console.log("action.payload", action.payload);

      return {
        ...state,
        all: [{ message: action.payload, checked: false }, ...state.all],
        count: state.count + 1,
      };
    case "ALL-NOTIFICATION":
      return {
        ...state,
        all: action.payload,
        count: action.payload.length,
      };

    default:
      return state;
  }
};

export default notificationReducer;
