import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { warningFeedback } from "../../Utils/swalFeedback";
import { useSelector } from "react-redux";

function DepositCheckHome() {
  const navigate = useNavigate();
  // const isAuthenticated = useSelector((state) => state.user.isAuth);
  const isAuthenticated = localStorage.getItem('access');
  const handelStartDeposit = () => {
    if (isAuthenticated) {
      navigate("/SelectBank");
    } else {
      warningFeedback("You must Login first");
    }
  };
  return (
    <Container fluid style={{ marginTop: "50px" }}>
      <h1 className="text-center">Digital Check Deposit Benefits</h1>
      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Row xs={1} md={2} className="g-4">
            <Col>
              <Card className="text-center">
                <Card.Body>
                  <Card.Text>
                    <i className="bi bi-bank icons_fontsize"></i>
                  </Card.Text>{" "}
                  <Card.Title>Accessible At All Times</Card.Title>
                  <Card.Text>
                    Photograph and deposit your check from your website or
                    mobile app anytime, anywhere.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <Card.Body>
                  <Card.Text>
                    <i className="bi bi-bank2 icons_fontsize"></i>{" "}
                  </Card.Text>{" "}
                  <Card.Title>Fast, No-Fee Deposit !</Card.Title>
                  <Card.Text>
                    Just like at an ATM, your funds are deposited immediately
                    into your account,
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <Card.Body>
                  <Card.Text>
                    <i className="bi bi-cash icons_fontsize"></i>{" "}
                  </Card.Text>{" "}
                  <Card.Title>Remote Practice</Card.Title>
                  <Card.Text>
                    No need to go to a branch anymore! You can deposit up to
                    50,000 TND per month anytime.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <Card.Body>
                  <Card.Text>
                    <i className="bi bi-coin icons_fontsize"></i>{" "}
                  </Card.Text>{" "}
                  <Card.Title>Easy To Use</Card.Title>
                  <Card.Text>
                    Nearly 80% of people who use digital check deposit are
                    satisfied with its ease of use.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col
          md={4}
          className="d-flex justify-content-center align-items-center"
        >
          <img
            src="https://img.freepik.com/vecteurs-premium/personnage-dessin-anime-femme-faisant-investissement-argent-dans-banque_776652-828.jpg"
            alt="Right Side"
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </Col>
      </Row>
      <div className="text-center mt-4">
        <Button onClick={handelStartDeposit} className="mt-2">
          Get Started
        </Button>
      </div>
    </Container>
  );
}

export default DepositCheckHome;
