import { combineReducers } from "redux";
import userReducer from "./userReducer";
import loaderReducer from "./loaderReducer";
import checkReducer from "./checkReducer";
import notificationReducer from "./notificationReducer";
import reclamationReducer from "./reclamationReducer";

const rootReducer = combineReducers({
  user: userReducer,
  loader: loaderReducer,
  check: checkReducer,
  notification: notificationReducer,
  reclamation: reclamationReducer,
});
export default rootReducer;
