import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requesVerifyEFA } from "../Redux/Actions/userActionsCreators";

function ModalConfirmLogin2FA({
  showConfirmationModal,
  setShowConfirmationModal,
  email,
  password,
}) {
  const { Formik } = formik;
  const schema = yup.object().shape({
    confirmationCode: yup.string().required("Confirmation code is required"),
  });

  const initialValues = {
    confirmationCode: "",
  };

  const handleClose = () => setShowConfirmationModal(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Modal show={showConfirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={(values) => {
              dispatch(requesVerifyEFA(email,
                password,
                values.confirmationCode,
                navigate,
                setShowConfirmationModal))
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3 text-start">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationConfirmationCode"
                  >
                    <Form.Label>Confirmation code</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        placeholder="Enter code"
                        aria-describedby="inputGroupconfirmationcode"
                        name="confirmationCode"
                        value={values.confirmationCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          !!errors.confirmationCode && touched.confirmationCode
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmationCode}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Confirm
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );

}
  

export default ModalConfirmLogin2FA;
