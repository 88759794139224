import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import arrow from "../images/clickM.png";

function UploadCard({ handleStartWebcam, handleFileUpload }) {
  return (
    <div>
      <Card className="text-center" style={{ border: "2px dashed #0D7CC3" }}>
        <Card.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Label>
            <i
              className="bi bi-webcam cursor-pointer "
              style={{ fontSize: "60px", color: "blue", cursor: "pointer" }}
              onClick={handleStartWebcam}
            ></i>
          </Form.Label>
          <Card.Title>Take a photo of your check</Card.Title>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              Make sure that your camera is connected and that your browser
              allows camera capture. When ready, click Capture
            </Form.Label>
            <Card.Title>Or</Card.Title>
            <Card.Text>
              <Card.Img src={arrow} style={{ width: "50px" }} /> Drag & Drop or
              click here <Card.Img src={arrow} style={{ width: "50px" }} />
            </Card.Text>
            <Form.Control
              type="file"
              onChange={handleFileUpload}
              accept="image/*"
            />
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UploadCard;
