import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../images/signIn.jpg";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import { requestLoginUser } from "../../Redux/Actions/userActionsCreators";
import ReCAPTCHA from "react-google-recaptcha";
import ModalConfirmLogin2FA from "../../Components/ModalConfirmLogin2FA";
import * as formik from "formik";
import * as yup from "yup";
import { errorFeedback } from "../../Utils/swalFeedback";

function SignIn() {
  const { Formik } = formik;
  const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    terms: false,
  });

  function handleValidation(values) {
    if (!recaptchaValue) {
      errorFeedback("Veuillez compléter le reCAPTCHA pour continuer.");
      return;
    }
    setFormValues(values);
    dispatch(
      requestLoginUser(
        values.email,
        values.password,
        recaptchaValue,
        setShowConfirmationModal,
        navigate
      )
    );
  }
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  useEffect(() => {
    const executeRecaptcha = async () => {
      if (window.grecaptcha) {
        const token = await window.grecaptcha.execute(
          "6LcTWiIqAAAAADdeQzO9nQhL7k1bvJXmcZOfLnlD",
          { action: "LOGIN" }
        );
        setRecaptchaToken(token);
      }
    };

    // Charge le script reCAPTCHA et exécute la fonction
    executeRecaptcha();
  }, []);

  return (
    <div>
      <ModalConfirmLogin2FA
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        email={formValues.email}
        password={formValues.password}
      />
      <Container className="my-5">
        <Card
          style={{
            border: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.10) 5px 1px 10px, rgba(0, 0, 0, 0.10) 0px 1px 1px, rgba(0, 0, 0, 0.16) 0px 5px 10px",
          }}
        >
          <Row className="g-0">
            <Col md="6" style={{ background: "whitesmoke" }}>
              <div className="p-4">
                <Formik
                  validationSchema={schema}
                  onSubmit={handleValidation}
                  initialValues={formValues}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row className="mb-3 text-start">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikEmail"
                        >
                          <Form.Label>Email / Username</Form.Label>
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              @
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              aria-describedby="inputGroupPrepend"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.email && touched.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3 text-start">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.password && touched.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Text as={Link} to="/ForgetPassword" >
                          Forget password
                        </Form.Text>
                      </Row>
                      <Form.Group className="mb-3 text-start d-flex">
                        <Form.Check
                          required
                          name="terms"
                          label="Agree to terms and conditions"
                          onChange={handleChange}
                          isInvalid={!!errors.terms && touched.terms}
                          feedback={errors.terms}
                          feedbackType="invalid"
                          id="validationFormik0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 text-start">
                        <ReCAPTCHA
                          sitekey="6LfzWSIqAAAAAM28fpSAxsCPI9ngLTTGSWxFQEqg"
                          onChange={handleRecaptchaChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 d-flex justify-content-evenly align-items-center">
                        <Button type="submit" className="theme-btn-one nav-link">Sign In</Button>
                        <Button   className="theme-btn-one nav-link" as={Link} to="/SignUp">Sign Up</Button>
                      
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>

            <Col md="6">
              <Image
                src={logo}
                alt="login form"
                className="rounded-start w-100"
                fluid
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default SignIn;
