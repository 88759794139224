import React from "react";
import Card from "react-bootstrap/Card";

function Banks({ image, name, isBlurred, isSelected, onClick }) {
  return (
    <Card
      className={`d-flex flex-row align-items-center ${
        isSelected ? "zoomed" : ""
      }`}
      style={{
        width: "20rem",
        height: "120px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Card.Img
        className={`me-2`}
        style={{ width: "100px", filter: isBlurred ? `blur(2px)` : "" }}
        src={image}
      />
      <Card.Body style={{ filter: isBlurred ? `blur(2px)` : "" }}>
        <Card.Text>{name}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Banks;
