import React from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";

function AccountDetails() {
  return (
    <Col xl={8} >
      {/* Account details card */}
      <Card className="mb-4">
        <Card.Header>Account Details</Card.Header>
        <Card.Body>
          <Form>
            {/* Form Group (username) */}
            <Form.Group className="mb-3" controlId="inputUsername">
              <Form.Label className="small mb-1">
                Username (how your name will appear to other users on the site)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your username"
                defaultValue="username"
                autoComplete="username"

              />
            </Form.Group>
            {/* Form Row */}
            <Row className="gx-3 mb-3">
              {/* Form Group (first name) */}
              <Col md={6}>
                <Form.Group controlId="inputFirstName">
                  <Form.Label className="small mb-1">First name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your first name"
                    defaultValue="Valerie"
                  />
                </Form.Group>
              </Col>
              {/* Form Group (last name) */}
              <Col md={6}>
                <Form.Group controlId="inputLastName">
                  <Form.Label className="small mb-1">Last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name"
                    defaultValue="Luna"
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* Form Row */}
            <Row className="gx-3 mb-3">
              {/* Form Group (organization name) */}
              <Col md={6}>
                <Form.Group controlId="inputOrgName">
                  <Form.Label className="small mb-1">
                    Organization name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your organization name"
                    defaultValue="Start Bootstrap"
                  />
                </Form.Group>
              </Col>
              {/* Form Group (location) */}
              <Col md={6}>
                <Form.Group controlId="inputLocation">
                  <Form.Label className="small mb-1">Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your location"
                    defaultValue="San Francisco, CA"
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* Form Group (email address) */}
            <Form.Group className="mb-3" controlId="inputEmailAddress">
              <Form.Label className="small mb-1">Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                defaultValue="name@example.com"
              />
            </Form.Group>
            {/* Form Row */}
            <Row className="gx-3 mb-3">
              {/* Form Group (phone number) */}
              <Col md={6}>
                <Form.Group controlId="inputPhone">
                  <Form.Label className="small mb-1">Phone number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter your phone number"
                    defaultValue="555-123-4567"
                  />
                </Form.Group>
              </Col>
              {/* Form Group (birthday) */}
              <Col md={6}>
                <Form.Group controlId="inputBirthday">
                  <Form.Label className="small mb-1">Birthday</Form.Label>
                  <Form.Control
                    type="text"
                    name="birthday"
                    placeholder="Enter your birthday"
                    defaultValue="06/10/1988"
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* Save changes button */}
            <Button variant="primary" type="button">
              Save changes
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default AccountDetails;
