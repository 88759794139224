import React from "react";
import { Col, Card, Form, Button } from "react-bootstrap";

const NotificationSettings = () => {
  return (
    <>
      <Col lg={4}>
        {/* Email notifications preferences card */}
        <Card className="card-header-actions mb-4">
          <Card.Header>
            Email Notifications
            <Form.Check
              type="switch"
              id="flexSwitchCheckChecked"
              label=""
              defaultChecked
              className="form-check form-switch"
            />
          </Card.Header>
          <Card.Body>
            <Form>
              {/* Form Group (default email) */}
              <Form.Group className="mb-3">
                <Form.Label
                  className="small mb-1"
                  htmlFor="inputNotificationEmail"
                >
                  Default notification email
                </Form.Label>
                <Form.Control
                  id="inputNotificationEmail"
                  type="email"
                  value="name@example.com"
                  disabled
                />
              </Form.Group>
              {/* Form Group (email updates checkboxes) */}
              <Form.Group className="mb-0">
                <Form.Label className="small mb-2">
                  Choose which types of email updates you receive
                </Form.Label>
                <Form.Check
                  id="checkAccountChanges"
                  label="Changes made to your account"
                  defaultChecked
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkAccountGroups"
                  label="Changes are made to groups you're part of"
                  defaultChecked
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkProductUpdates"
                  label="Product updates for products you've purchased or starred"
                  defaultChecked
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkProductNew"
                  label="Information on new products and services"
                  defaultChecked
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkPromotional"
                  label="Marketing and promotional offers"
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkSecurity"
                  label="Security alerts"
                  defaultChecked
                  disabled
                  className="form-check"
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        {/* SMS push notifications card */}
      </Col>
      <Col lg={4} className="mb-3">
        <Card className="card-header-actions mb-4">
          <Card.Header>
            Push Notifications
            <Form.Check
              type="switch"
              id="smsToggleSwitch"
              label=""
              defaultChecked
              className="form-check form-switch"
            />
          </Card.Header>
          <Card.Body>
            <Form>
              {/* Form Group (default SMS number) */}
              <Form.Group className="mb-3">
                <Form.Label
                  className="small mb-1"
                  htmlFor="inputNotificationSms"
                >
                  Default SMS number
                </Form.Label>
                <Form.Control
                  id="inputNotificationSms"
                  type="tel"
                  value="123-456-7890"
                  disabled
                />
              </Form.Group>
              {/* Form Group (SMS updates checkboxes) */}
              <Form.Group className="mb-0">
                <Form.Label className="small mb-2">
                  Choose which types of push notifications you receive
                </Form.Label>
                <Form.Check
                  id="checkSmsComment"
                  label="Someone comments on your post"
                  defaultChecked
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkSmsShare"
                  label="Someone shares your post"
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkSmsFollow"
                  label="A user follows your account"
                  defaultChecked
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkSmsGroup"
                  label="New posts are made in groups you're part of"
                  className="form-check mb-2"
                />
                <Form.Check
                  id="checkSmsPrivateMessage"
                  label="You receive a private message"
                  defaultChecked
                  className="form-check"
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        {/* Notifications preferences card */}
        <Card>
          <Card.Header>Notification Preferences</Card.Header>
          <Card.Body>
            <Form>
              {/* Form Group (notification preference checkboxes) */}
              <Form.Check
                id="checkAutoGroup"
                label="Automatically subscribe to group notifications"
                defaultChecked
                className="form-check mb-2"
              />
              <Form.Check
                id="checkAutoProduct"
                label="Automatically subscribe to new product notifications"
                className="form-check mb-3"
              />
              {/* Submit button */}
              <Button variant="danger-soft" className="text-danger">
                Unsubscribe from all notifications
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default NotificationSettings;
