import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import * as formik from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";

import {useNavigate} from 'react-router-dom'

import { requestForgetPassword } from "../../Redux/Actions/userActionsCreators";

function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { Formik } = formik;
  const schema = yup.object().shape({
    email: yup.string().required().email(),
  });

  const initialValues = {
    email: "",
  };

  return (
    <div>
      <Container className="my-5 d-flex justify-content-center">
        <Card
          style={{
            width: "70%",
            border: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.10) 5px 1px 10px, rgba(0, 0, 0, 0.10) 0px 1px 1px, rgba(0, 0, 0, 0.16) 0px 5px 10px",
          }}
        >
          <Card.Title className="mt-5 text-center">Forget Password</Card.Title>
          <Row className="g-0">
            <Col md="6">
              <div className="p-4">
                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => {
                    dispatch(requestForgetPassword(values.email, navigate));
                  }}
                  initialValues={initialValues}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row className="mb-3 text-start">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikUsername"
                        >
                          <Form.Label>Email</Form.Label>
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              @
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              aria-describedby="inputGroupPrepend"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.email && touched.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Form.Group className="mb-3 d-flex justify-content-evenly align-items-center">
                        <Button type="submit" className="theme-btn-one nav-link">Send Link</Button>
                        <Button  className="theme-btn-one nav-link"  as={Link} to="/SignIn">Retour</Button>

                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
            <Col
              md="6"
              className="d-flex align-items-center justify-content-center"
            >
              <Card.Img
                variant="center"
                src="https://t3.ftcdn.net/jpg/04/92/75/18/240_F_492751838_Ybun2zwpQC8AZv11AwZLdXJk4cUrTt5z.jpg"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default ForgetPassword;
