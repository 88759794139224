import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import logo from "../../images/onlineBanking.png";
import InputGroup from "react-bootstrap/InputGroup";
import { requestRegisterRD } from "../../Redux/Actions/userActionsCreators";
import { requestRegisterMorale } from "../../Redux/Actions/userActionsCreators";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

// Validation schema using Yup
const validationSchema = Yup.lazy((values) => {
  switch (values.accountType) {
    case "Personne Physique":
      return Yup.object().shape({
        prenom: Yup.string().required("First Name is required"),
        nom: Yup.string().required("Last Name is required"),
        username: Yup.string().required("Username is required"),
        cin: Yup.string()
          .matches(/^\d{8}$/, "CIN must be exactly 8 digits")
          .required("CIN is required"),
        date_naissance: Yup.date().required("Date of birth is required"),
        civilite: Yup.string().required("Civil status is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            "Password must be alphanumeric and contain at least one uppercase letter"
          )
          .required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Confirm Password is required"),
        terms: Yup.boolean().oneOf(
          [true],
          "Terms and Conditions must be accepted"
        ),
        accountType: Yup.string().required("Account type is required"),
      });

    case "Personne Morale":
      return Yup.object().shape({
        nom_societe: Yup.string().required("Company Name is required"),
        matricule_fiscale: Yup.string().required(
          "Matricule Fiscale is required"
        ),
        username: Yup.string().required("Username is required"),
        telephone: Yup.string()
          .matches(/^\d{8}$/, "Telephone must be exactly 8 digits")
          .required("Telephone number is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            "Password must be alphanumeric and contain at least one uppercase letter"
          )
          .required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Confirm Password is required"),
        terms: Yup.boolean().oneOf(
          [true],
          "Terms and Conditions must be accepted"
        ),
        accountType: Yup.string().required("Account type is required"),
      });

    default:
      return Yup.object().shape({
        accountType: Yup.string().required("Account type is required"),
      });
  }
});

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    accountType: "", // Account type, either 'physique' or 'morale'
    // Default values for 'Personne Physique'
    prenom: "",
    username: "",
    nom: "",
    cin: "",
    date_naissance: "",
    civilite: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
    // Values for 'Personne Morale'
    nom_societe: "",
    matricule_fiscale: "",
    telephone: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    values.accountType === "Personne Physique"
      ? dispatch(requestRegisterRD(values, navigate))
      : dispatch(requestRegisterMorale(values, navigate));
    setSubmitting(false);
  };

  return (
    <div>
      <Container className="my-5">
        <Card
          style={{
            border: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.10) 5px 1px 10px, rgba(0, 0, 0, 0.10) 0px 1px 1px, rgba(0, 0, 0, 0.16) 0px 5px 10px",
          }}
        >
          <Row className="g-0">
            <Col md="6" style={{ background: "whitesmoke" }}>
              <Card.Body className="d-flex flex-column">
                <h3 className="fw-normal my-4 pb-3">Add New Account</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row className="mb-3 text-start">
                        <Col md={12}>
                          <Form.Group controlId="validationFormikAccountType">
                            <Form.Label>Account Type</Form.Label>
                            <Form.Select
                              name="accountType"
                              value={values.accountType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                !!errors.accountType && touched.accountType
                              }
                            >
                              <option value="">Select Account Type</option>
                              <option value="Personne Physique">
                                Personne Physique
                              </option>
                              <option value="Personne Morale">
                                Personne Morale
                              </option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.accountType}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      {values.accountType === "Personne Physique" ? (
                        <>
                          <Row className="mb-3 text-start">
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikFirstName"
                              >
                                <Form.Label>First Name</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    name="prenom"
                                    value={values.prenom}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.prenom && touched.prenom
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.prenom}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikLastName"
                              >
                                <Form.Label>Last Name</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    name="nom"
                                    value={values.lastnomName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.nom && touched.nom}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.nom}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3 text-start">
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikUsername"
                              >
                                <Form.Label>Username</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.username && touched.username
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikCivilite"
                              >
                                <Form.Label>Civilité</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Select
                                    name="civilite"
                                    value={values.civilite}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.civilite && touched.civilite
                                    }
                                  >
                                    <option value="">Sélectionnez...</option>
                                    <option value="Monsieur">Monsieur</option>
                                    <option value="Madame">Madame</option>
                                    <option value="Mademoiselle">
                                      Mademoiselle
                                    </option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.civilite}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikDateOfBirth"
                              >
                                <Form.Label>Date de naissance</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="date"
                                    placeholder="Date de naissance"
                                    name="date_naissance"
                                    value={values.date_naissance}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.date_naissance &&
                                      touched.date_naissance
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.date_naissance}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3 text-start">
                            <Col md={8}>
                              <Form.Group controlId="validationFormikEmail">
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                  <InputGroup.Text id="inputGroupPrepend">
                                    @
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.email && touched.email}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group controlId="validationFormikCIN">
                                <Form.Label>CIN</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="CIN"
                                    name="cin"
                                    value={values.cin}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.cin && touched.cin}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.cin}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3 text-start">
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikPassword"
                              >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  placeholder="Password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={
                                    !!errors.password && touched.password
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.password}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikConfirmPassword"
                              >
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  placeholder="Confirm Password"
                                  name="confirmPassword"
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={
                                    !!errors.confirmPassword &&
                                    touched.confirmPassword
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.confirmPassword}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}

                      {values.accountType === "Personne Morale" ? (
                        <>
                          <Row className="mb-3 text-start">
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikUsername"
                              >
                                <Form.Label>Username</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.username && touched.username
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>

                            <Col md={4}>
                              <Form.Group controlId="validationFormikCompanyName">
                                <Form.Label>Nom société</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="text"
                                    placeholder="Nom société"
                                    aria-describedby="inputGroupPrepend"
                                    name="nom_societe"
                                    value={values.nom_societe}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.nom_societe &&
                                      touched.nom_societe
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.nom_societe}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3 text-start">
                            <Col md={12}>
                              <Form.Group controlId="validationFormikEmail">
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                  <InputGroup.Text id="inputGroupPrepend">
                                    @
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    aria-describedby="inputGroupPrepend"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.email && touched.email}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3 text-start">
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikPassword"
                              >
                                <Form.Label>Password</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    aria-describedby="inputGroupPrepend"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.password && touched.password
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikConfirmPassword"
                              >
                                <Form.Label>Confirm password</Form.Label>
                                <InputGroup hasValidation>
                                  <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    aria-describedby="inputGroupPrepend"
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      !!errors.confirmPassword &&
                                      touched.confirmPassword
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-start">
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikTaxId"
                              >
                                <Form.Label>Matricule fiscale</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Matricule fiscale"
                                  name="matricule_fiscale"
                                  value={values.matricule_fiscale}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={
                                    !!errors.matricule_fiscale &&
                                    touched.matricule_fiscale
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.matricule_fiscale}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationFormikPhone"
                              >
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Téléphone"
                                  name="telephone"
                                  value={values.telephone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={
                                    !!errors.telephone && touched.telephone
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.telephone}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}

                      <Form.Group className="mb-3 text-start d-flex">
                        <Form.Check
                          required
                          name="terms"
                          label="Agree to terms and conditions"
                          onChange={handleChange}
                          isInvalid={!!errors.terms && touched.terms}
                          feedback={errors.terms}
                          feedbackType="invalid"
                          id="validationFormikTerms"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 d-flex justify-content-evenly align-items-center">
                        <Button
                          className="theme-btn-one nav-link"
                          type="submit"
                        >
                          Sign up
                        </Button>
                        <Button
                          className="theme-btn-one nav-link"
                          as={Link}
                          to="/SignIn"
                        >
                          Sign In
                        </Button>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Col>

            <Col md="6">
              <Card.Img
                src={logo}
                alt="Sign up form"
                className="rounded-start w-100"
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default SignUp;
