import { errorFeedback } from "../../Utils/swalFeedback";

export const loadingStrated = () => {
  return {
    type: "REQUEST-STARTED",
  };
};
export const loadingSucceded = () => {
  return {
    type: "REQUEST-SUCCEDED",
  };
};
export const loadingError = (err) => {
  let errorMessage = err.message || "Request failed";
  if (
    err &&
    err.response &&
    err.response.data &&
    err.response.data.message &&
    typeof err.response.data.message === "string"
  ) {
    errorMessage = err.response.data.message;
  }
  if (
    err &&
    err.response &&
    err.response.data &&
    !err.response.data.message &&
    typeof err.response.data === "object"
  ) {
    const firstErrorKey = Object.keys(err.response.data)[0];
    const firstError = err.response.data[firstErrorKey][0];
    const howToFix = err.response.data[firstErrorKey][1] || ""; // Check if there's a second message
    console.log(firstError, firstErrorKey, howToFix);

    errorMessage = howToFix
      ? `${firstErrorKey} / ${firstError} / ${howToFix}`
      : `${firstErrorKey} / ${firstError}`;
  }
  if (err && err.response && err.response.data && err.response.data.messages) {
    errorMessage = err.response.data.messages[0]?.message;
  }
  if (errorMessage) {
    errorFeedback(errorMessage);
  }
  return {
    type: "REQUEST-FAILED",
    payload: errorMessage,
  };
};
