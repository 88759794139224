import {
  loadingStrated,
  loadingError,
  loadingSucceded,
} from "./loaderActionsCreators";

import axiosInstance from "../../Services/api";

let socket;

const pushNotification = (message) => {
  return { type: "PUSH", payload: message };
};
const getALLNotification = (notificationsData) => {
  return { type: "ALL-NOTIFICATION", payload: notificationsData };
};

// const playNotificationSound = () => {
//   const audio = new Audio(
//     "../../Utils/NotificationSound/level-up-2-199574.mp3"
//   );
//   audio.play().catch((error) => {
//     console.log("Playback failed. User interaction required.");
//   });
// };

const initializeWebSocket = (dispatch, access) => {
  if (access) {
    // Fermer un WebSocket existant avant de créer une nouvelle connexion
    if (socket) {
      socket.close(); // Assurez-vous de fermer l'ancienne connexion avant d'en créer une nouvelle
      console.log("Closing existing WebSocket connection.");
    }

    const webSocketURL = process.env.REACT_APP_URL_WEBSOCKET;
    console.log("webSocketURL:", webSocketURL);
    socket = new WebSocket(
      `${webSocketURL}/ws/reclamation-cheque-status-listener/?token=${access}`
    );

    socket.onopen = () => console.log("WebSocket connection established");
    socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log("Received notification:", data);

      if (data && data.message && data.status) {
        const notification = data.message;
        dispatch(pushNotification(notification));
      } else {
        console.log("Received an unexpected message format.");
      }
    };

    socket.onerror = (error) => console.log("WebSocket error:", error);

    socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event.reason);
      if (!event.wasClean) {
        console.log("Attempting to reconnect...");
        setTimeout(() => initializeWebSocket(dispatch, access), 5000);
      }
    };

    // Retourner une fonction de nettoyage qui ferme le socket
    return () => {
      if (socket && socket.readyState === 1) {
        socket.close();
        console.log("WebSocket connection closed.");
      }
    };
  }
};

export const requestPushNotification = () => {
  return (dispatch, getState) => {
    const access = getState().user.access;
    return initializeWebSocket(dispatch, access); // Ensure this returns the cleanup function
  };
};

export const requestGetAllNotifications = () => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const response = await axiosInstance.get("/notification/");
      if (response.data) {
        dispatch(getALLNotification(response.data));
      }
    } catch (error) {
      dispatch(loadingError(error));
    }
  };
};
