import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import { requestResetPassword } from "../../Redux/Actions/userActionsCreators";

import * as formik from "formik";
import * as yup from "yup";
import { errorFeedback, warningFeedback } from "../../Utils/swalFeedback";

function ResetPassword() {
  const { id, token } = useParams();
  const { Formik } = formik;
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("Veuillez entrer votre nouveau mot de passe"),
    reenterNewPassword: yup
      .string()
      .required("Veuillez entrer de nouveau votre mot de passe"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initalValues = {
    newPassword: "",
    reenterNewPassword: "",
  };

  function handleValidation(values) {
    if (values.newPassword !== values.reenterNewPassword) {
      warningFeedback("Passwords dosen't match");
      return;
    }
    dispatch(requestResetPassword(id, token, values.newPassword, navigate));
  }

  return (
    <div>
      <Container className="my-5">
        <Card
          style={{
            border: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.10) 5px 1px 10px, rgba(0, 0, 0, 0.10) 0px 1px 1px, rgba(0, 0, 0, 0.16) 0px 5px 10px",
          }}
        >
          <Card.Title className="mt-2 text-center">Reset Password</Card.Title>

          <Row className="g-0">
            <Col md="6" style={{ background: "whitesmoke" }}>
              <div className="p-4">
                <Formik
                  validationSchema={schema}
                  onSubmit={handleValidation}
                  initialValues={initalValues}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row className="mb-3 text-start">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikPassword"
                        >
                          <Form.Label>New password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="New password"
                            name="newPassword"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              !!errors.newPassword && touched.newPassword
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.newPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3 text-start">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikPassword"
                        >
                          <Form.Label>Re enter new password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Re enter new password"
                            name="reenterNewPassword"
                            value={values.reenterNewPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              !!errors.reenterNewPassword &&
                              touched.reenterNewPassword
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.reenterNewPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-3 d-flex justify-content-evenly align-items-center">
                          <Button type="submit">Reset Password</Button>
                        </Form.Group>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>

            <Col md="6">
              <Image
                src="https://www.manageengine.com/products/ad-manager/images/admp-pwd-reset.png"
                alt="login form"
                className="rounded-start w-100"
                fluid
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default ResetPassword;
