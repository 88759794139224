import React, { useState } from "react";
import { Tabs, Tab, Button, Container } from "react-bootstrap";
import TakePicture from "./TakePicture";
import ExtractedCheckData from "./ExtractedCheckData";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requestExtractOCRData } from "../../Redux/Actions/checkActionsCreators";
import { errorFeedback } from "../../Utils/swalFeedback";

const CheckDepositSteps = () => {
  const [key, setKey] = useState("step1");
  const [screenshot, setScreenshot] = useState(null);
  const [secondScreenshot, setSecondScreenshot] = useState(null);
  const [isOcrDataExtracted, setIsOcrDataExtracted] = useState(false); // State to track OCR data extraction
  const location = useLocation();
  const { rib } = location.state || {};
  const dispatch = useDispatch();
  const [error, setError] = useState(null); // State to store the error message

  const handleNextStep2 = async () => {
    setError(null); // Clear any previous error
    try {
      if (!screenshot && !secondScreenshot) {
        errorFeedback("Front and Back check images not given");
      } else if (!screenshot && secondScreenshot) {
        errorFeedback("Front check Image not given");
      } else if (screenshot && !secondScreenshot) {
        errorFeedback("Back check Image not given");
      } else {
        await dispatch(requestExtractOCRData(screenshot));
        setIsOcrDataExtracted(true); // Set the flag to true after successful extraction
        setKey("step3");
      }
    } catch (err) {
      setError("Failed to extract data. Please try again."); // Set error message
    }
  };

  const handleTabSelect = (k) => {
    if (k === "step3" && !isOcrDataExtracted) {
      // Prevent navigation to step3 if OCR data has not been extracted
      errorFeedback(
        "Please complete the previous steps to access this section."
      );
    } else {
      setKey(k);
    }
  };

  return (
    <Container>
      <Tabs
        id="stepper-tabs"
        activeKey={key}
        onSelect={handleTabSelect}
        className="button-container custom-tabs mb-5 mt-5"
      >
        <Tab
          eventKey="step1"
          title="Step 1"
          className="custom-tab-content position-relative"
        >
          <div>
            <TakePicture
              title="Front Check Photo"
              face="Front"
              screenshot={screenshot}
              setScreenshot={setScreenshot}
            />
            <Button
              onClick={() => setKey("step2")}
              variant="primary"
              className="position-absolute top_end"
            >
              Next
            </Button>
          </div>
        </Tab>
        <Tab
          eventKey="step2"
          title="Step 2"
          className="custom-tab-content position-relative"
        >
          <TakePicture
            title="Back Check Photo"
            face="Back"
            screenshot={secondScreenshot}
            setScreenshot={setSecondScreenshot}
          />
          <div>
            <Button
              onClick={() => setKey("step1")}
              variant="secondary"
              className="position-absolute top_start"
            >
              Back
            </Button>
            <Button
              onClick={() => handleNextStep2()}
              variant="primary"
              className="position-absolute top_end"
            >
              Next
            </Button>
          </div>
        </Tab>
        {isOcrDataExtracted && (
          <Tab
            eventKey="step3"
            title="Step 3"
            className="custom-tab-content position-relative"
          >
            <ExtractedCheckData
              screenshot={screenshot}
              secondScreenshot={secondScreenshot}
              rib={rib}
            />
            <div>
              <Button
                onClick={() => setKey("step2")}
                variant="secondary"
                className="position-absolute top_start"
              >
                Back
              </Button>
            </div>
          </Tab>
        )}
      </Tabs>
    </Container>
  );
};

export default CheckDepositSteps;
