import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { warningFeedback } from "../Utils/swalFeedback";

function Home() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuth);
  const handelStartDeposit = () => {
    if (isAuthenticated) {
      navigate("/SelectBank");
    } else {
      warningFeedback("You must login first");
    }
  };

  const loadScript = (src) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    // Load the external JS files when the component mounts
    loadScript("/js/script.js");
    loadScript("/js/caroussel.js");
  }, []);
  return (
    <>
      {" "}
      <div>
        <section className="banner-section style-one" id="accueil">
          <div className="banner-carousel owl-theme owl-carousel owl-dots-none owl-nav-none">
            <div className="slide-item style-one">
              <div
                className="image-layer"
                style={{ backgroundImage: "url(img/scan8.jpg)" }}
              ></div>
              <div className="pattern-layer">
                <div
                  className="pattern-1"
                  style={{ backgroundImage: "url(img/pattern-1.png)" }}
                ></div>
                <div
                  className="pattern-2"
                  style={{ backgroundImage: "url(img/pattern-2.png)" }}
                ></div>
              </div>
              <div className="auto-container">
                <div className="row clearfix">
                  <div className="col-xl-6 col-lg-12 col-md-12 content-column">
                    <div className="content-box">
                      <span>
                        {" "}
                        Dépôt de Chèques en Ligne avec Banque BT Simple et
                        Rapide
                      </span>
                      <h1>Banque BT en Tunisie</h1>
                      <div className="btn-box">
                        <a href="#" className="theme-btn-two">
                          Commencer
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-item style-two">
              <div
                className="image-layer"
                style={{ backgroundImage: "url(img/scan7.jpg)" }}
              ></div>
              <div className="pattern-layer">
                <div
                  className="pattern-1"
                  style={{ backgroundImage: "url(img/pattern-3.png)" }}
                ></div>
                <div
                  className="pattern-2"
                  style={{ backgroundImage: "url(img/pattern-4.png)" }}
                ></div>
              </div>
              <div className="auto-container">
                <div className="row clearfix">
                  <div className="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                    <div className="content-box">
                      <span>
                        {" "}
                        Déposez vos chèques facilement en ligne avec Banque BT.
                      </span>
                      <h1>Banque BT en Tunisie</h1>
                      <div className="btn-box">
                        <a href="#" className="theme-btn-two">
                          Commencer
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-item style-one">
              <div
                className="image-layer"
                style={{
                  backgroundImage:
                    "url(https://images.pexels.com/photos/8276364/pexels-photo-8276364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
                }}
              ></div>
              <div className="pattern-layer">
                <div
                  className="pattern-1"
                  style={{ backgroundImage: "url(img/pattern-1.png)" }}
                ></div>
                <div
                  className="pattern-2"
                  style={{ backgroundImage: "url(img/pattern-2.png)" }}
                ></div>
              </div>
              <div className="auto-container">
                <div className="row clearfix">
                  <div className="col-xl-7 col-lg-12 col-md-12 content-column">
                    <div className="content-box">
                      <span>
                        Déposer vos chèques à distance en quelques clics.
                      </span>
                      <h1>Banque BT en Tunisie</h1>
                      <div className="btn-box">
                        <a href="#" className="theme-btn-two">
                          Commencer
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="welcome-section" id="services">
          <div className="auto-container">
            <div className="sec-title centred">
              <span className="top-title">
                Bienvenue au service de dépôt de chèques en ligne de BT BANK !
              </span>
              <h2>Dépôt de chèques en ligne BT BANK :</h2>
              <p>
                {" "}
                Chez BT BANK, nous simplifions le dépôt de vos chèques en vous
                permettant de les soumettre directement en ligne depuis chez
                vous.
              </p>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure className="image-box js-tilt">
                  <img src="img/picture1.png" alt="" />
                </figure>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                <div id="content_block_1">
                  <div className="content-box">
                    <div className="tabs-box">
                      <div className="tab-btn-box">
                        <ul className="tab-btns tab-buttons clearfix">
                          <li className="tab-btn active-btn" data-tab="#tab-1">
                            <h5 className="mb-0">
                              <i className="bi bi-person-fill"></i> Physique
                            </h5>
                            <i className="bi bi-arrow-up arrow-icon  "></i>
                          </li>
                          <li className="tab-btn" data-tab="#tab-2">
                            <h5 className="mb-0">
                              <i className="bi bi-building-fill"></i> Morale
                            </h5>
                            <i className="bi bi-arrow-up arrow-icon"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="tabs-content">
                        <div className="tab active-tab" id="tab-1">
                          <div className="inner-box">
                            <h5>Personne Physique</h5>
                            <h2>
                              Déposer un chèque en ligne en tant que personne
                              physique :
                            </h2>
                            <p>
                              Le dépôt de chèques en ligne pour une personne
                              physique chez BT BANK est un service sécurisé qui
                              vous permet, en tant que client particulier, de
                              déposer vos chèques sans avoir à vous déplacer.
                            </p>
                            <ul className="list clearfix">
                              <li>
                                <figure className="icon-box">
                                  <img src="img/icon-1.png" alt="" />
                                </figure>
                                <h4>Scanner le chèque </h4>
                              </li>
                              <li>
                                <figure className="icon-box">
                                  <img src="img/icon-2.png" alt="" />
                                </figure>
                                <h4>Soumettre le dépôt</h4>
                              </li>
                            </ul>
                            <div className="link">
                              <Button
                                onClick={handelStartDeposit}
                                className="theme-btn-two"
                                id="Button_react"
                              >
                                <i className="bi bi-arrow-right"></i> Déposer le
                                chèque
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="tab" id="tab-2">
                          <div className="inner-box">
                            <h5>Personne Morale </h5>
                            <h2>
                              Déposer un chèque en ligne en tant que personne
                              morale :
                            </h2>
                            <p>
                              Le dépôt de chèques en ligne pour une personne
                              morale (entreprise, association, organisation)
                              chez BT BANK est un service sécurisé et pratique
                            </p>
                            <ul className="list clearfix">
                              <li>
                                <figure className="icon-box">
                                  <img src="img/icon-71.png" alt="" />
                                </figure>
                                <h4>Des employers</h4>
                              </li>
                              <li>
                                <figure className="icon-box">
                                  <img src="img/icon-72.png" alt="" />
                                </figure>
                                <h4>Entreprise </h4>
                              </li>
                            </ul>
                            <div className="link">
                              <Button
                                onClick={handelStartDeposit}
                                className="theme-btn-two"
                                id="Button_react"
                              >
                                <i className="bi bi-arrow-right"></i> Déposer le
                                chèque
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-section" id="propos">
          <div
            className="pattern-layer"
            style={{ backgroundImage: "url(img/pattern-5.png)" }}
          ></div>
          <div className="auto-container">
            <div className="inner-container">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div id="content_block_2">
                    <div className="content-box centred">
                      <figure className="image-box" style={{ margin: "0" }}>
                        <img src="img/scan8.jpg" alt="" />
                      </figure>
                      <div className="inner-box">
                        <figure className="icon-box">
                          <img
                            src="https://www.bt.com.tn/Themes/BT/Content/Logo_bt.png"
                            alt=""
                          />
                        </figure>
                        <h3>Banque de Tunisie BT</h3>
                        <Button
                          onClick={handelStartDeposit}
                          className="theme-btn-two"
                          id="Button_react"
                        >
                          <i className="bi bi-arrow-right"></i> Commencer
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div id="content_block_3">
                    <div className="content-box">
                      <div className="sec-title">
                        <span className="top-title">A propos </span>
                        <h2>Dépôt de chèques en ligne Banque BT</h2>
                      </div>
                      <div className="text-justify">
                        <p>
                          La Banque de Tunisie (BT) a mis en place un service de
                          dépôt de chèques en ligne, facilitant ainsi les
                          opérations bancaires pour ses clients. Ce service
                          innovant permet aux particuliers et aux entreprises de
                          déposer leurs chèques sans avoir à se déplacer en
                          agence, en utilisant simplement leur smartphone ou
                          leur ordinateur.
                        </p>
                      </div>
                      <div className="link">
                        <a href="#">
                          <i className="bi bi-arrow-right"></i>En Savoir Plus
                        </a>
                      </div>
                      <div className="author-box">
                        <div className="author-text">
                          <h3>Votre soutien est notre confiance..</h3>
                        </div>
                        <div className="author-info">
                          <figure className="author-thumb">
                            <img
                              src="https://cv.hrizi.tn/Untitled-2.png"
                              alt=""
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-section">
          <div className="auto-container">
            <div className="sec-title centred">
              <span className="top-title">Solutions Que Nous Proposons</span>
              <h2>Dépôt des cheques en ligne</h2>
              <p>
                Les solutions que nous proposons visent à simplifier les
                opérations de dépôt des cheques grâce à cette plateforme en
                ligne.
              </p>
            </div>
            <div className="three-item-carousel owl-carousel owl-theme owl-nav-none owl-dot-style-one">
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="https://cerclemediateursbancaires.fr/wp-content/uploads/2024/04/iStock-915451034-1200x800.jpg"
                      alt=""
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img src="img/icon-4.png" alt="" />
                        </figure>
                        <h4>Rapide </h4>
                      </div>
                      <div className="link">
                        <a href="#">Plus de détails</a>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        Le processus est simple et peut être réalisé en quelques
                        étapes via l'application mobile.
                      </p>
                      <a href="#">
                        <i className="bi bi-arrow-right"></i>Plus de détails
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="https://pic.clubic.com/a257a5111807380/1000x667/smart/banque-en-ligne.jpg"
                      alt=""
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img src="img/icon-5.png" alt="" />
                        </figure>
                        <h4>Sécurisé</h4>
                      </div>
                      <div className="link">
                        <a href="#">Plus de détails</a>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        L'application utilise des technologies de cryptage pour
                        garantir la confidentialité des données.
                      </p>
                      <a href="#">
                        <i className="bi bi-arrow-right"></i>Plus de détails
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      src="https://www.planete-auto.fr/wp-content/uploads/2023/04/cheque-bancaire.webp"
                      alt=""
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img src="img/icon-6.png" alt="" />
                        </figure>
                        <h4>Accessibilité </h4>
                      </div>
                      <div className="link">
                        <a href="#">Plus de détails</a>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        Ce service est accessible 24h/24 et 7j/7, permettant aux
                        clients de réaliser leurs dépôts à tout moment.
                      </p>
                      <a href="#">
                        <i className="bi bi-arrow-right"></i>Plus de détails
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="recruitment-technology" id="avantages">
          <div className="auto-container">
            <div className="row clearfix">
              <div
                className="col-lg-6 col-md-12 col-sm-12 image-column"
                id="img-avantages"
              >
                <figure className="image-box js-tilt clearfix">
                  <img src="img/picture2.png" alt="" id="img-avantages" />
                </figure>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_4">
                  <div className="content-box">
                    <div className="sec-title">
                      <span className="top-title">
                        Avantages pour les clients <b>BT</b> :
                      </span>
                      <h2>Avantages:</h2>
                      <p>
                        Ce service innovant permet aux particuliers et aux
                        entreprises de déposer leurs chèques sans avoir à se
                        déplacer en agence.
                      </p>
                    </div>
                    <div className="inner-box">
                      <div
                        className="single-item wow fadeInRight animated animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="inner">
                          <figure className="icon-box">
                            <img
                              style={{ width: "4rem" }}
                              src="https://cdn-icons-png.flaticon.com/256/8725/8725477.png"
                              alt=""
                            />
                          </figure>
                          <h3>
                            <span>01</span>
                            <a href="#">
                              Gain de temps :
                              <i className="bi bi-arrow-right"></i>
                            </a>
                          </h3>
                          <p>
                            Le client n'a plus besoin de se rendre en agence.
                          </p>
                        </div>
                      </div>
                      <div
                        className="single-item wow fadeInRight animated animated"
                        data-wow-delay="300ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="inner">
                          <figure className="icon-box">
                            <img
                              style={{ width: "4rem" }}
                              src="https://cdn-icons-png.flaticon.com/512/9485/9485586.png"
                              alt=""
                            />
                          </figure>
                          <h3>
                            <span>02</span>
                            <a href="#">
                              Suivi en temps réel :{" "}
                              <i className="bi bi-arrow-right"></i>
                            </a>
                          </h3>
                          <p>Suivre l’état de traitement du dépôt en ligne.</p>
                        </div>
                      </div>
                      <div
                        className="single-item wow fadeInRight animated animated"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="inner">
                          <figure className="icon-box">
                            <img
                              style={{ width: "4rem" }}
                              src="https://img.freepik.com/free-vector/check-mark-shield-with-shadow_78370-4413.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1725062400&semt=ais_hybrid"
                              alt=""
                            />
                          </figure>
                          <h3>
                            <span>03</span>
                            <a href="#">
                              Service sécurisé :
                              <i className="bi bi-arrow-right"></i>
                            </a>
                          </h3>
                          <p>Protégés contre les risques liés à la fraude.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industries-section bg-color-1" id="solutions">
          <div
            className="pattern-layer"
            style={{ backgroundImage: "url(img/pattern-7.png)" }}
          ></div>
          <div className="auto-container">
            <div className="tabs-box">
              <div className="row clearfix">
                <div className="col-xl-4 col-lg-12 col-sm-12 btn-column">
                  <div className="sec-title darck">
                    <span className="top-title">Pocedure dépot chèque</span>
                    <h2 style={{ color: "white" }}>En ligne</h2>
                  </div>
                  <div className="tab-btn-box">
                    <ul className="tab-btns tab-buttons clearfix text-left">
                      <li className="tab-btn active-btn" data-tab="#tab-3">
                        <figure className="icon-box">
                          <img src="img/step1.png" alt="" />
                        </figure>
                        <h3>Connexion </h3>
                      </li>
                      <li className="tab-btn" data-tab="#tab-4">
                        <figure className="icon-box">
                          <img src="img/step2.png" alt="" />
                        </figure>
                        <h3> Dépôt de chèque</h3>
                      </li>
                      <li className="tab-btn" data-tab="#tab-5">
                        <figure className="icon-box">
                          <img src="img/step3.png" alt="" />
                        </figure>
                        <h3> Scanne le chèque</h3>
                      </li>
                      <li className="tab-btn" data-tab="#tab-6">
                        <figure className="icon-box">
                          <img src="img/step4.png" alt="" />
                        </figure>
                        <h3> Soumettre le dépôt</h3>
                      </li>
                      <li className="tab-btn" data-tab="#tab-7">
                        <figure className="icon-box">
                          <img src="img/step5.png" alt="" />
                        </figure>
                        <h3> Confirmation </h3>
                      </li>
                      <li className="tab-btn" data-tab="#tab-8">
                        <figure className="icon-box">
                          <img src="img/step6.png" alt="" />
                        </figure>
                        <h3>Transaction</h3>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-7 col-lg-12 col-sm-12 content-column"
                  id="procedure"
                >
                  <div className="tabs-content">
                    <div className="tab active-tab" id="tab-3">
                      <div className="inner-box">
                        <figure className="image-box">
                          <img
                            style={{ width: "30%" }}
                            src="img/Image1.png"
                            alt=""
                          />
                        </figure>
                        <div className="content-box">
                          <div className="text">
                            <h2>Connexion</h2>
                            <span>
                              Accédez à votre compte en ligne via le site de BT
                              BANK ou l’application mobile.
                            </span>
                          </div>
                          <div className="line"></div>
                          <div className="link">
                            <a href="#">
                              <i className="bi bi-arrow-right-circle-fill"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab" id="tab-4">
                      <div className="inner-box">
                        <figure className="image-box">
                          <img
                            style={{ width: "30%" }}
                            src="img/Image2.png"
                            alt=""
                          />
                        </figure>
                        <div className="content-box">
                          <div className="text">
                            <h2>Dépôt de chèque</h2>
                            <span>
                              Sélectionner l'option "Dépôt de chèque"{" "}
                            </span>
                          </div>
                          <div className="line"></div>
                          <div className="link">
                            <a href="#">
                              <i className="bi bi-arrow-right-circle-fill"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab" id="tab-5">
                      <div className="inner-box">
                        <figure className="image-box">
                          <img
                            style={{ width: "30%" }}
                            src="img/Image3.png"
                            alt=""
                          />
                        </figure>
                        <div className="content-box">
                          <div className="text">
                            <h2>Scanner le chèque</h2>
                            <span>
                              Assurez-vous que toutes les informations sont
                              lisibles.
                            </span>
                          </div>
                          <div className="line"></div>
                          <div className="link">
                            <a href="#">
                              <i className="bi bi-arrow-right-circle-fill"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab" id="tab-6">
                      <div className="inner-box">
                        <figure className="image-box">
                          <img
                            style={{ width: "30%" }}
                            src="img/Image4.png"
                            alt=""
                          />
                        </figure>
                        <div className="content-box">
                          <div className="text">
                            <h2>Soumettre le dépôt</h2>
                            <span>
                              {" "}
                              Une fois les photos téléchargées, vérifiez les
                              informations et validez le dépôt.
                            </span>
                          </div>
                          <div className="line"></div>
                          <div className="link">
                            <a href="#">
                              <i className="bi bi-arrow-right-circle-fill"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab" id="tab-7">
                      <div className="inner-box">
                        <figure className="image-box">
                          <img
                            style={{ width: "30%" }}
                            src="img/Image5.png"
                            alt=""
                          />
                        </figure>
                        <div className="content-box">
                          <div className="text">
                            <h2>Confirmation </h2>
                            <span>
                              Vous recevrez un message de confirmation lorsque
                              le chèque est déposer
                            </span>
                          </div>
                          <div className="line"></div>
                          <div className="link">
                            <a href="#">
                              <i className="bi bi-arrow-right-circle-fill"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab" id="tab-8">
                      <div className="inner-box">
                        <figure className="image-box">
                          <img
                            style={{ width: "30%" }}
                            src="img/Image2.png"
                            alt=""
                          />
                        </figure>
                        <div className="content-box">
                          <div className="text">
                            <h2>Transaction</h2>
                            <span>
                              Vous avez la possibilité de consulter vos chèques
                              et leur statut.
                            </span>
                          </div>
                          <div className="line"></div>
                          <div className="link">
                            <a href="#">
                              <i className="bi bi-arrow-right-circle-fill"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="process-section centred" id="procedure-emis">
          <div className="auto-container">
            <div className="sec-title">
              <span className="top-title">Procédure du Chèque</span>
              <h2>Comment est-ce possible</h2>
              <p>
                Gérer un chèque émis par une autre personne et le déposer en
                ligne.
              </p>
            </div>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 process-block">
                <div
                  className="process-block-one wow fadeInUp animated animated"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box ">
                    <figure className="icon-box">
                      <img src="img/icon-16.png" alt="" />
                      <span>01</span>
                    </figure>
                    <div className="lower-content">
                      <h3>Initialisation du chèque</h3>
                      <p>Validation par une autre personne.</p>
                      <a href="#">Plus de détails</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 process-block">
                <div
                  className="process-block-one wow fadeInUp animated animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <figure className="icon-box">
                      <img src="img/icon-17.png" alt="" />
                      <span>02</span>
                    </figure>
                    <div className="lower-content">
                      <h3>Numérisation du chèque</h3>
                      <p>Gain de temps.</p>
                      <a href="#">Plus de détails</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 process-block">
                <div
                  className="process-block-one wow fadeInUp animated animated"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <figure className="icon-box">
                      <img src="img/icon-18.png" alt="" />
                      <span>03</span>
                    </figure>
                    <div className="lower-content">
                      <h3>Transaction </h3>
                      <p>Soumettre le dépôt</p>
                      <a href="#">Plus de détails</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="awards-section" id="excellence">
          <div className="auto-container">
            <div className="sec-title centred">
              <span className="top-title">Notre Excellence</span>
              <h2>Votre soutien est notre confiance.</h2>
              <p>
                Découvrez les avantages supplémentaires de notre plateforme de
                dépôt de chèques en ligne ainsi que de notre application
                Android.
              </p>
            </div>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12 inner-column">
                <div className="inner-block">
                  <div className="single-award-block">
                    <div className="inner-box">
                      <div className="upper-box">
                        <figure className="icon-box">
                          <img src="img/icon-19.png" alt="" />
                        </figure>
                        <h3>Flexibilité </h3>
                      </div>
                      <ul className="lower-box">
                        <li className="text-justify">
                          <p>
                            Déposez vos chèques depuis votre bureau, domicile ou
                            en déplacement.{" "}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="single-award-block">
                    <div className="inner-box">
                      <div className="upper-box">
                        <figure className="icon-box">
                          <img src="img/icon-19.png" alt="" />
                        </figure>
                        <h3>Gain de temps</h3>
                      </div>
                      <ul className="lower-box">
                        <li className="text-justify">
                          <p>
                            Plus besoin de vous déplacer en agence, déposez vos
                            chèques en quelques clics.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 image-column">
                <figure className="image-box js-tilt">
                  <img src="img/brand.png" alt="" />
                </figure>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 inner-column">
                <div className="inner-block">
                  <div className="single-award-block">
                    <div className="inner-box">
                      <div className="upper-box">
                        <figure className="icon-box">
                          <img src="img/icon-19.png" alt="" />
                        </figure>
                        <h3>Historique </h3>
                      </div>
                      <ul className="lower-box">
                        <li className="text-justify">
                          <p>
                            Historique des dépôts en ligne pour une meilleure
                            gestion des transactions.
                          </p>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="single-award-block">
                    <div className="inner-box">
                      <div className="upper-box">
                        <figure className="icon-box">
                          <img src="img/icon-19.png" alt="" />
                        </figure>
                        <h3>Notifications </h3>
                      </div>
                      <ul className="lower-box">
                        <li className="text-justify">
                          <p>
                            {" "}
                            Des Alertes en temps réel via l'application mobile
                            ou web à chaque étape du dépôt.
                          </p>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="main-footer bg-color-1" id="footer">
          {/* <div className="pattern-layer"
                style={{background: "url(https://www.bt.com.tn/Themes/BT/Content/logo_bt.png)"}}>
            </div> */}
          <div className="auto-container">
            <div className="footer-top">
              <div className="widget-section">
                <div className="row clearfix">
                  <div className="col-lg-2 col-md-6 col-sm-12-f footer-column">
                    <div className="footer-widget about-widget">
                      <div className="widget-title">
                        <img
                          style={{ width: "60%" }}
                          src="https://www.bt.com.tn/Themes/BT/Content/logo_bt.png"
                        />
                      </div>
                      <div className="text">
                        <p>
                          Banque Tunisie <br />
                          BT{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-12-f footer-column">
                    <div className="footer-widget links-widget">
                      <div className="widget-title">
                        <h3 className="text-left">Liens Utiles</h3>
                      </div>
                      <ul className="links-list clearfix text-left">
                        <li>
                          <a href="#">Accueil</a>
                        </li>
                        <li>
                          <a href="#">Service </a>
                        </li>
                        <li>
                          <a href="#">A propos</a>
                        </li>
                        <li>
                          <a href="#">Solutions </a>
                        </li>
                        <li>
                          <a href="#">Avantages</a>
                        </li>
                        <li>
                          <a href="#">Pocedure </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12-f footer-column">
                    <div className="footer-widget contact-widget">
                      <div className="single-info-box">
                        <div className="widget-title">
                          <h3>Contactez-Nous</h3>
                        </div>
                        <ul className="info-box clearfix">
                          <li>
                            <a href="tel:81101212">81 10 12 12</a>
                          </li>
                          <li>
                            <a href="mailto:callcenter@bt.com.tn">
                              callcenter@bt.com.tn
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="footer-widget about-widget">
                        <ul className="social-links clearfix">
                          <li>
                            <a href="#">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-google-plus-g"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-subscribe">
              <div className="text centred">
                <h3 className="text-left">Abonnez-vous</h3>
              </div>
              <form
                action="contact.html"
                method="post"
                className="subscribe-form"
              >
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Saisir votre email  ..."
                    required=""
                  />
                  <button type="submit">
                    <i className="bi bi-arrow-right"></i>Soumettre
                  </button>
                </div>
              </form>
            </div>
            <div className="footer-bottom clearfix">
              <div className="copyright pull-left">
                <p>
                  &copy; 2024 <a href="#">ABSHORE</a>, Tous Droits Réservés.
                </p>
              </div>
              <ul className="footer-nav pull-right clearfix">
                <li>
                  <a href="#">Politique de Confidentialité</a>
                </li>
                <li>
                  <a href="#">Conditions Générales</a>
                </li>
                <li>
                  <a href="#">Plan du Site</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>

        {/* <div classNameName="header-heading">
          <h2>WELCOME TO YOUR</h2>
          <h1>
            <span>ABSHORE</span> WEBSITE
          </h1>
          <p classNameName="details">
            Simplify your check deposits and ensure compliance with ease.
          </p>
          <Button
            as={Link}
            to="/SelectBank"
            style={{
              width: "150px",
              backgroundColor: "#E68F36",
              border: "#E68F36",
            }}
            size="lg"
          >
            Get Started
          </Button>{" "}
        </div>
      </div>
      <div>
        <HowToDeposit />
        <AboutUs />
        <Contact />
        <Footer /> */}
      </div>
      {/* <button className="scroll-top scroll-to-target" data-target="html"><i className="bi bi-arrow-up"></i>Top</button> */}
    </>
  );
}

export default Home;
