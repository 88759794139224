import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Form, Button } from "react-bootstrap";
import { requestToggle2FA } from "../../Redux/Actions/userActionsCreators";

const ProfileSecurity = () => {
  const dispatch = useDispatch();
  const is2FAEnabled = useSelector(
    (state) => state.user.userInfo.is_two_factor_enabled
  );
  const handlePrivacyChange = (e) => {
    console.log(e.target.value);
    dispatch(requestToggle2FA(e.target.value));
  };
  return (
    <>
      <Col lg={4}>
        {/* Change password card */}
        <Card className="mb-4">
          <Card.Header>Change Password</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="currentPassword">
                <Form.Label className="small mb-1">Current Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter current password"
                  autoComplete="current-password"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label className="small mb-1">New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  autoComplete="new-password"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirmPassword">
                <Form.Label className="small mb-1">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  autoComplete="new-password"
                />
              </Form.Group>
              <Button variant="primary" type="button">
                Save
              </Button>
            </Form>
          </Card.Body>
        </Card>
        {/* Security preferences card */}
        <Card className="mb-4">
          <Card.Header>Security Preferences</Card.Header>
          <Card.Body>
            <h5 className="mb-1">Account Privacy</h5>
            <p className="small text-muted">
              By setting your account to private, your profile information and
              posts will not be visible to users outside of your user groups.
            </p>
            <Form>
              <Form.Check
                type="radio"
                id="radioPrivacy1"
                name="radioPrivacy"
                label="Public (posts are available to all users)"
                defaultChecked
              />
              <Form.Check
                type="radio"
                id="radioPrivacy2"
                name="radioPrivacy"
                label="Private (posts are available to only users in your groups)"
              />
            </Form>
            <hr className="my-4" />
            <h5 className="mb-1">Data Sharing</h5>
            <p className="small text-muted">
              Sharing usage data can help us to improve our products and better
              serve our users as they navigate through our application. When you
              agree to share usage data with us, crash reports and usage
              analytics will be automatically sent to our development team for
              investigation.
            </p>
            <Form>
              <Form.Check
                type="radio"
                id="radioUsage1"
                name="radioUsage"
                label="Yes, share data and crash reports with app developers"
                defaultChecked
              />
              <Form.Check
                type="radio"
                id="radioUsage2"
                name="radioUsage"
                label="No, limit my data sharing with app developers"
              />
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4}>
        {/* Two factor authentication card */}

        <Card className="mb-4">
          <Card.Header>Two-Factor Authentication</Card.Header>
          <Card.Body>
            <p>
              Add another level of security to your account by enabling
              two-factor authentication. We will send you a text message to
              verify your login attempts on unrecognized devices and browsers.
            </p>
            <Form>
              <Form.Check
                type="radio"
                id="radioPrivacy1"
                name="radioPrivacy"
                label="On"
                value="0"
                checked={is2FAEnabled == true ? true : false}
                onChange={handlePrivacyChange}
              />
              <Form.Check
                type="radio"
                id="radioPrivacy2"
                name="radioPrivacy"
                label="Off"
                value="1"
                checked={is2FAEnabled == true ? false : true}
                onChange={handlePrivacyChange}
              />
            </Form>
          </Card.Body>
        </Card>
        {/* Delete account card */}
        <Card className="mb-4">
          <Card.Header>Delete Account</Card.Header>
          <Card.Body>
            <p>
              Deleting your account is a permanent action and cannot be undone.
              If you are sure you want to delete your account, select the button
              below.
            </p>
            <Button variant="danger" type="button">
              I understand, delete my account
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default ProfileSecurity;
