import { errorFeedback, successFeedback } from "../../Utils/swalFeedback";
import {
  loadingStrated,
  loadingError,
  loadingSucceded,
} from "./loaderActionsCreators";
import axiosInstance from "../../Services/api";

const addAllReclamation = (Data) => {
  return { type: "ADD-ALL-RECLAMATION", payload: Data };
};
const sendReclamation = (reclamationObject) => {
  return { type: "ADD-ONE-RECLAMATION", payload: reclamationObject };
};

const requestSendReclamation = (reclamationObject, navigate) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const { sujet, description, cheque } = reclamationObject;
      const data = {
        sujet,
        description,
        cheque,
      };
      const response = await axiosInstance.post(`/reclamations/`, data);
      dispatch(loadingSucceded());
      successFeedback("Réclamation déposer avec succée");
      dispatch(sendReclamation(response.data));
      navigate("/ReclamationsList");
    } catch (error) {
      dispatch(loadingError(error));
    }
  };
};
const requestGetAllReclamation = () => {
  return async (dispatch) => {
    try {
      const appUrlBackend = process.env.REACT_APP_URL_BACKEND;
      console.log("appUrlBackend", appUrlBackend);
      const response = await axiosInstance.get(`/reclamations/`);
      if (response.data) {
        dispatch(addAllReclamation(response.data));
      }
    } catch (error) {
      errorFeedback(error);
    }
  };
};

export { requestSendReclamation, requestGetAllReclamation };
