import Swal from 'sweetalert2'

const successFeedback = (message) => {
    Swal.fire({
        title: 'Sucess',
        text: message,
        icon: 'success',
        confirmButtonText: 'Close'
      })}
const errorFeedback = (message) => {
    Swal.fire({
        title: 'Error!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Close'
      })}
const infoFeedback = (message) => {
    Swal.fire({
        title: 'Info',
        text: message,
        icon: 'info',
        confirmButtonText: 'Close'
      })}
const warningFeedback = (message) => {
    Swal.fire({
        title: 'Warning',
        text: message,
        icon: 'warning',
        confirmButtonText: 'Close'
      })}
const questionFeedback = (message) => {
    Swal.fire({
        title: 'Question',
        text: message,
        icon: 'question',
        confirmButtonText: 'Close'
      })}
export {
    successFeedback,
    errorFeedback,
    infoFeedback,
    warningFeedback,
    questionFeedback
}