import React from "react";
import { useSelector } from "react-redux";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import { useNavigate } from "react-router-dom";

DataTable.use(DT);

function CheckHistory() {
  const checksList = useSelector((state) => state.check.checksList);
  const navigate = useNavigate();

  const handleRowClick = (id_cheque) => {
    navigate("/Reclamations", { state: { check: id_cheque } });
  };

  const handleEdit = () => {
    console.log("Edit icon clicked");
  };

  const handleDelete = () => {
    console.log("Delete icon clicked");
  };

  const handleReclamationClick = (id_cheque) => {
    navigate(`/Reclamations/${id_cheque}`);
  };

  return (
    <div className="container mt-5 mb-5">
      <h2 className="text-center mb-4">Liste des Réclamations</h2>

      <div className="table-responsive">
        <DataTable
          data={checksList}
          className="display"
          columns={[
            { title: "#", data: "id_cheque" },
            { title: "Numéro de Chèque", data: "cheque_number" },
            { title: "Nom du Titulaire", data: "account_holder_name" },
            { title: "RIB Titulaire", data: "account_number" },
            {
              title: "Status",
              data: "status",
              render: (data) => {
                return `<span class="badge ${
                  data === "payed" ? "bg-success" : "bg-warning"
                }">${data}</span>`;
              },
            },
            {
              title: "Action",
              orderable: false,
              render: () => {
                return `
                  <div class="d-flex justify-content-center align-items-center gap-2">
                      <button class="btn btn-warning btn-sm edit-icon">Edit</button>
                      <button class="btn btn-danger btn-sm delete-icon">Delete</button>
                  </div>
                `;
              },
            },
            {
              title: "Réclamation",
              orderable: false,
              render: (data, type, row) => {
                return `
                  <i 
                    class="bi bi-card-heading fs-5 reclamation-icon" 
                    style="color: blue; cursor: pointer;"
                  ></i>
                `;
              },
            },
          ]}
          options={{
            createdRow: (row, data) => {
              // row.addEventListener("click", () => handleRowClick(data.id_cheque));
              row.querySelector(".edit-icon").addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                handleEdit();
              });
              row
                .querySelector(".delete-icon")
                .addEventListener("click", (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDelete();
                });
              row
                .querySelector(".reclamation-icon")
                .addEventListener("click", (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleRowClick(data);
                });
            },
            paging: true,
            responsive: true,
          }}
        />
      </div>
    </div>
  );
}

export default CheckHistory;
