import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestDepositCheck } from "../../Redux/Actions/checkActionsCreators";
import { requestCheckCreditWorthiness } from "../../Redux/Actions/checkActionsCreators";
import { errorFeedback } from "../../Utils/swalFeedback";

function ValidateDeposit({
  show,
  onHide,
  screenshot,
  secondScreenshot,
  values,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const submitCheck = () => {
    if (!isChecked) {
      errorFeedback("Assurez-vous de valider votre dépôt");
      return;
    }
    dispatch(
      requestDepositCheck(values, screenshot, secondScreenshot, navigate)
    );
  };
  const checkCreditWorthiness = () => {
    const { cheque_number, account_number } = values;
    const dataCheque = { cheque_number, account_number };
    if (cheque_number && account_number) {
      dispatch(requestCheckCreditWorthiness(dataCheque));
    } else {
      console.log("cheque or account number inexist");
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Read carefully before submitting your check deposit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {/* Left side for screenshots */}
            <Col xs={6}>
              {screenshot && (
                <div className="mb-3 dashed-border">
                  <img
                    src={screenshot}
                    alt="Screenshot"
                    width="100%"
                    height="auto"
                  />
                </div>
              )}
              {secondScreenshot && (
                <div className="dashed-border">
                  <img
                    src={secondScreenshot}
                    alt="Second Screenshot"
                    width="100%"
                    height="auto"
                  />
                </div>
              )}
            </Col>
            {/* Right side for values */}
            <Col xs={6}>
              <p>
                <strong>
                  <i className="bi bi-person-circle me-2"></i> Account Holder
                  Name:
                </strong>{" "}
                {values.account_holder_name}
              </p>
              <p>
                <strong>
                  <i className="bi bi-geo-alt-fill me-2"></i> Location:
                </strong>{" "}
                {values.location}
              </p>
              <p>
                <strong>
                  <i className="bi bi-123 me-2"></i> Amount (in numbers):
                </strong>{" "}
                {values.amount_in_numbers}
              </p>
              <p>
                <strong>
                  <i className="bi bi-cash-stack me-2"></i> Amount (in letters):
                </strong>{" "}
                {values.amount_in_letters}
              </p>
              <p>
                <strong>
                  <i className="bi bi-credit-card me-2"></i> Account Number:
                </strong>{" "}
                {values.account_number}
              </p>
              <p>
                <strong>
                  <i className="bi bi-receipt me-2"></i> Cheque Number:
                </strong>{" "}
                {values.cheque_number}
              </p>
              <p>
                <strong>
                  <i className="bi bi-calendar-check me-2"></i> Date:
                </strong>{" "}
                {values.date}
              </p>
              <p>
                <strong>
                  <i className="bi bi-person-check me-2"></i> Beneficiary:
                </strong>{" "}
                {values.payee}
              </p>
              <p>
                <strong>
                  <i className="bi bi-telephone me-2"></i> Phone:
                </strong>{" "}
                {values.phone}
              </p>
            </Col>
          </Row>
          <Form.Check type="checkbox" id="check-api-checkbox">
            <Form.Check.Input
              type="checkbox"
              isValid={isChecked}
              isInvalid={!isChecked} // Add dynamic invalid validation
              onChange={() => setIsChecked(!isChecked)}
            />
            <Form.Check.Label>
              Assurez-vous de valider votre dépôt en cochant cette case.
            </Form.Check.Label>
            <Form.Control.Feedback type="valid">
              You did it!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Do it here!
            </Form.Control.Feedback>
          </Form.Check>
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          className="d-flex justify-content-center"
          onClick={submitCheck}
          disabled={!isChecked}
        >
          Deposit
        </Button>
        <Button className="d-flex justify-content-center" onClick={checkCreditWorthiness}>
          Vérifier la solvabilité
        </Button>
        <Button className="d-flex justify-content-center" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ValidateDeposit;
