import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Navbar from "../Components/Navbar";

function CommonLayout() {
  return (
    <>
      <Navbar />
      <Container fluid style={{ padding: "inherit !important" }}>
        <main>
          {" "}
          <Outlet />
        </main>
      </Container>
    </>
  );
}

export default CommonLayout;
