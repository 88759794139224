import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bankExample from "../images/bankCheck.jpeg";

const Instructions = ({ face }) => {
  return (
    <Container>
      <Card className="px-2 my-1" style={{ border: "1px solid grey" }}>
        <Card.Body>
          <Row className="g-0">
            <Col md={12} className="text-center">
              <Card.Img
                src={bankExample}
                style={{ width: "100%", maxWidth: "700px" }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              style={{
                padding: "10px",
                textAlign: "start",
                fontSize: "14px",
              }}
            >
              <h5>Instructions:</h5>
              <ul>
                <li>1. Make sure that your camera is connected.</li>
                <li>2. Ensure your browser allows camera capture.</li>
                <li>3. Position the bank check within the camera frame.</li>
                <li>
                  4. Verify that you are taking a picture of the {face} of the
                  check.
                </li>
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Instructions;
