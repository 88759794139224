const initialValues = {
  loading: false,
  error: "",
};
const loaderReducer = (state = initialValues, action) => {
  switch (action.type) {
    case "REQUEST-STARTED":
      return { ...state, loading: true };
    case "REQUEST-FAILED":
      return { ...state, loading: false, error: action.payload };
    case "REQUEST-SUCCEDED":
      return { ...state, loading: false};
    default:
      return state;
  }
};
export default loaderReducer;
