import axios from "axios";
import { updateAccessToken } from "../Redux/Actions/userActionsCreators";
import store from "../Redux/store"; // Your Redux store

const appUrlBackend = process.env.REACT_APP_URL_BACKEND;
const axiosInstance = axios.create({
  baseURL: `${appUrlBackend}/api`,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const access = localStorage.getItem("access");
    if (access) {
      request.headers["Authorization"] = `Bearer ${access}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response, // Directly return successful responses.
  async (error) => {
    const originalRequest = error.config;

    // Si c'est une requête de login, pas de tentative de refresh
    if (originalRequest.url.includes("/login/")) {
      return Promise.reject(error); // Retourner l'erreur directement
    }
    if (originalRequest.url.includes("/verifyotp/")) {
      return Promise.reject(error); // Retourner l'erreur directement
    }
    if (originalRequest.url.includes("/agence/")) {
      return Promise.reject(error); // Retourner l'erreur directement
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
      try {
        const refreshToken = localStorage.getItem("refresh"); // Retrieve the stored refresh token.
        // Make a request to your auth server to refresh the token.
        const response = await axios.post(
          `${appUrlBackend}/api/token/refresh/`,
          {
            refresh: refreshToken,
          }
        );
        const { access, refresh } = response.data;
        // Store the new access and refresh tokens.
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", refresh);

        // Dispatch an action to update the access token in the store.
        store.dispatch(updateAccessToken(access));

        // Update the authorization header with the new access token.
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access}`;
        return axiosInstance(originalRequest); // Retry the original request with the new access token.
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        // window.location.replace("/SignIn");
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error); // For all other errors, return the error as is.
  }
);

export default axiosInstance;
