import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import * as yup from "yup";

import { useSelector } from "react-redux";
import ValidateDeposit from "./ValidateDeposit";

function ExtractedCheckData({ screenshot, secondScreenshot, rib }) {
  const extractedData = useSelector((state) => state.check.OcrExrtactedData);
  console.log("extracted data from extractecheckdata display", extractedData);
  const [initialValues, setInitialValues] = useState({
    account_holder_name: "",
    amount_in_numbers: "",
    amount_in_letters: "",
    account_number: "",
    cheque_number: "",
    date: "",
    location: "",
    payee: "",
    phone: "",
  });
  const [showModalValidate, setShowModalValidate] = useState(false);

  // Update initialValues when extractedData changes
  useEffect(() => {
    if (extractedData) {
      setInitialValues({
        account_holder_name: extractedData.account_holder_name || "",
        amount_in_numbers: extractedData.amount_in_numbers || "",
        amount_in_letters: extractedData.amount_in_letters || "",
        account_number: extractedData.account_number || "",
        cheque_number: extractedData.cheque_number || "",
        date: extractedData.date || "",
        location: extractedData.location || "",
        payee: extractedData.payee || "",
        phone: extractedData.phone || "",
      });
    }
  }, [extractedData]);
  const schema = yup.object().shape({
    account_holder_name: yup.string().required("Nom benificière is required"),
    // nomEmetteur: yup.string().required("Nom emetteur is required"),
    amount_in_numbers: yup
      .number("Amount must be a number")
      .required("Amount is required"),
    amount_in_letters: yup.string().required("Hand writing amount is required"),
    account_number: yup.string().required("Rib emetteur is required"),
    cheque_number: yup.string().required("Numéro chéque is required"),
    date: yup.date().required("Date chéque is required"),
    location: yup.string().required("Location chéque is required"),
    payee: yup.string().required("Agency chéque is required"),
    phone: yup.string().required("Phone is required"),
  });
  return (
    <div style={{ marginTop: "50px" }}>
      <ValidateDeposit
        show={showModalValidate}
        onHide={() => setShowModalValidate(false)}
        screenshot={screenshot}
        secondScreenshot={secondScreenshot}
        values={initialValues}
      />

      <h1 className="mb-3">Display Extracted Values</h1>
      <Container className="mb-3">
        <Row className="d-flex">
          <Col xs={6}>
            {screenshot && (
              <div className="dashed-border">
                <img
                  src={screenshot}
                  alt="Screenshot"
                  width="100%"
                  height="auto"
                />
              </div>
            )}
          </Col>
          <Col xs={6}>
            {secondScreenshot && (
              <div className="dashed-border">
                <img
                  src={secondScreenshot}
                  alt="Second Screenshot"
                  width="100%"
                  height="auto"
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Formik
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setInitialValues(values);
          setShowModalValidate(true); // Show the modal after submission
          setSubmitting(false); // Stop the form from being submitted
        }}
        enableReinitialize={true}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isSubmitting,
          errors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col >
                <Form.Group as={Col} controlId="validationFormikRib">
                  <Form.Label>RIB Benificière</Form.Label>
                  <Form.Control type="text" name="rib" value={rib} disabled />
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikLastname">
                  <Form.Label>Nom Benificière</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nom Benificière"
                    name="account_holder_name"
                    value={values.account_holder_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !!errors.account_holder_name &&
                      touched.account_holder_name
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.account_holder_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikZip">
                  <Form.Label>Numéro Chéque</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Numéro Chéque"
                    name="cheque_number"
                    value={values.cheque_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.cheque_number && touched.cheque_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cheque_number}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikCity">
                  <Form.Label>Amount Check</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Amount"
                    name="amount_in_numbers"
                    value={values.amount_in_numbers}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !!errors.amount_in_numbers && touched.amount_in_numbers
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.amount_in_numbers}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikState">
                  <Form.Label>Hand writing Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Hand writing amount"
                    name="amount_in_letters"
                    value={values.amount_in_letters}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !!errors.amount_in_letters && touched.amount_in_letters
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.amount_in_letters}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="validationFormikZip">
                  <Form.Label>Rib Emetteur</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Rib Emetteur"
                    name="account_number"
                    value={values.account_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !!errors.account_number && touched.account_number
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.account_number}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikZip">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="date"
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.date && touched.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikZip">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Location"
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.location && touched.location}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.location}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikZip">
                  <Form.Label>Agency</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Agency"
                    name="payee"
                    value={values.payee}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.payee && touched.payee}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payee}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormikZip">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.phone && touched.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Button type="submit" disabled={isSubmitting}>
              Validate
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ExtractedCheckData;
