import React from "react";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneSquare,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function Contact() {
  return (
    <div>
      <Form>
        <Container className="my-5">
          <Card
            style={{
              border: "transparent",
              boxShadow:
                "rgba(0, 0, 0, 0.10) 0px 1px 1px, rgba(0, 0, 0, 0.16) 0px 5px 10px",
            }}
          >
            <Row className="g-0">
              <Col md="6">
                <Card.Body
                  className="d-flex flex-column"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <h3
                    className="fw-normal my-4 pb-3"
                    style={{ letterSpacing: "1px" }}
                  >
                    Contact Us
                  </h3>
                  <Form.Group className="mb-4">
                    <Form.Control type="text" placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control type="email" placeholder="E-mail Address" />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control type="text" placeholder="Subject" />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      as="textarea"
                      placeholder="Write a message ..."
                    />
                  </Form.Group>
                  <Button
                    style={{ background: "#E68F36", border: "#E68F36" }}
                    type="submit"
                  >
                    {" "}
                    Send Now{" "}
                  </Button>
                </Card.Body>
              </Col>
              <Col
                md="6"
                style={{
                  background: "#0D7CC3",
                  padding: "20px",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <h3
                  style={{
                    color: "white",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  Let's get in touch
                </h3>
                <p
                  style={{
                    color: "white",
                    borderBottom: "0.5px solid white",
                    marginBottom: "20px",
                    paddingBottom: "20px",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  We're open for any suggestions or just to have a chat
                </p>
                <h5
                  style={{
                    color: "white",
                    borderBottom: "0.5px solid white",
                    marginTop: "30px",
                    marginBottom: "20px",
                    paddingBottom: "10px",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <FontAwesomeIcon icon={faGlobe} />
                  <span> E-mail Address : Commercial@abshore.com</span>
                </h5>
                <h5
                  style={{
                    color: "white",
                    borderBottom: "0.5px solid white",
                    marginBottom: "20px",
                    paddingBottom: "20px",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span>E-mail Address : Commercial@abshore.com</span>
                </h5>
                <h5
                  style={{
                    color: "white",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <FontAwesomeIcon icon={faPhoneSquare} />
                  <span>
                    {" "}
                    Phone numbers : +216 71 96 34 53 / +216 71 96 34 66
                  </span>
                </h5>
              </Col>
            </Row>
          </Card>
        </Container>
      </Form>
    </div>
  );
}

export default Contact;
