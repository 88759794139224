import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bankExample from "../images/bankCheck.jpeg";

const Instructions = (props) => {
  const { face } = props;
  return (
    <Container className="my-5">
      <Card style={{ border: "1px solid grey" }}>
        <Row className="g-0">
          <Col md="6">
            <Card.Body
              className="d-flex flex-column"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Card.Img
                src={bankExample}
                style={{ width: "100%", maxWidth: "700px" }}
              />
            </Card.Body>
          </Col>
          <Col
            md="6"
            style={{
              padding: "20px",
              alignContent: "center",
              textAlign: "start",
            }}
          >
            <h3>Instructions :</h3>
            <p>1. Make sure that your camera is connected </p>
            <p>2. Make sure that your browser allows camera capture </p>
            <p>3. Fix the bank check in the seize of the camera </p>
            <p>4. Make sure that you are taking picture of the {face} check </p>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Instructions;
