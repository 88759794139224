import React from "react";
import { useSelector } from "react-redux";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";

DataTable.use(DT);

function ReclamationList() {
  const reclamations = useSelector((state) => state.reclamation.all);

  // Map reclamations data to the required structure for DataTable
  const data = reclamations.map((reclamation) => ({
    sujet: reclamation.sujet,
    description: reclamation.description,
    cheque_number: reclamation.cheque.cheque_number,
  }));

  return (
    <div className="container mt-5 mb-5">
      <h2 className="text-center mb-4">Liste des Réclamations</h2>

      <div className="table-responsive">
        <DataTable
          data={data} // No manual sorting needed here
          columns={[
            { title: "Sujet", data: "sujet", orderable: true }, // Sorting enabled
            { title: "Description", data: "description", orderable: true }, // Sorting enabled
            {
              title: "Numero du chèque",
              data: "cheque_number",
              orderable: true,
            }, // Sorting enabled
          ]}
          className="display"
          options={{
            responsive: true,     
            paging: true,
          }}
        />
      </div>
    </div>
  );
}

export default ReclamationList;
