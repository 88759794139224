// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    transition: background-color 0.3s, color 0.3s;
  }
  
  .light {
    background-color: white !important;
    color: black !important;
  }
  
  .dark {
    background-color: #2a2a2b !important;
    color: white  !important;
  }
`, "",{"version":3,"sources":["webpack://./src/CustomCSS/DarkLight.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;EAC/C;;EAEA;IACE,kCAAkC;IAClC,uBAAuB;EACzB;;EAEA;IACE,oCAAoC;IACpC,wBAAwB;EAC1B","sourcesContent":["body {\n    transition: background-color 0.3s, color 0.3s;\n  }\n  \n  .light {\n    background-color: white !important;\n    color: black !important;\n  }\n  \n  .dark {\n    background-color: #2a2a2b !important;\n    color: white  !important;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
