import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { requestSendReclamation } from "../../Redux/Actions/reclamationsActionsCreators";

function Reclamations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { check } = location.state || {};
  const { checksList } = useSelector((state) => state.check);

  const schema = yup.object().shape({
    description: yup
      .string()
      .max(50, "Description doit comporter 50 caractères ou moins")
      .required("Description is required"),
    sujet: yup
      .string()
      .max(200, "La Sujet doit comporter 200 caractères ou moins")
      .required("Sujet is required"),
    cheque_number: yup.string().required("Numéro de chèque est requis"),
    account_holder_name: yup
      .string()
      .required("Titulaire du compte est requis"),
  });

  const initialValues = {
    sujet: "",
    description: "",
    cheque_number: check ? check.cheque_number : "",
    account_holder_name: check ? check.account_holder_name : "",
    cheque: check ? check.id_cheque : "",
  };

  const handleChangeCheckData = (e, setFieldValue) => {
    const selectedCheck = checksList.find(
      (checkItem) => checkItem.id_cheque === parseInt(e.target.value)
    );
    if (selectedCheck) {
      setFieldValue("cheque_number", selectedCheck.cheque_number);
      setFieldValue("account_holder_name", selectedCheck.account_holder_name);
      setFieldValue("cheque", selectedCheck.id_cheque);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center" style={{ marginTop: "50px" }}>
        <Col xs={12} md={8} lg={6}>
          <h2 className="text-center">Réclamation</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
              dispatch(requestSendReclamation(values, navigate));
              resetForm();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isSubmitting,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                {/* Row for select, cheque number, and account holder name */}
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="select">
                        Liste des Chéques
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) =>
                          handleChangeCheckData(e, setFieldValue)
                        }
                        value={values.cheque_number} // Optionally, set a default value if needed
                      >
                        <option>Selectionner un chéque</option>
                        {checksList.map((checkItem) => (
                          <option
                            key={checkItem.id_cheque}
                            value={checkItem.id_cheque}
                          >
                            {checkItem.cheque_number} /{" "}
                            {checkItem.account_holder_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="cheque_number">
                        Numéro de chèque
                      </Form.Label>
                      <Form.Control
                        name="cheque_number"
                        type="text"
                        value={values.cheque_number}
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="account_holder_name">
                        Titulaire du compte
                      </Form.Label>
                      <Form.Control
                        name="account_holder_name"
                        type="text"
                        value={values.account_holder_name}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="cheque">Cheque id </Form.Label>
                      <Form.Control
                        name="cheque"
                        type="text"
                        value={values.cheque}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="title">Sujet</Form.Label>
                  <Form.Control
                    name="sujet"
                    type="text"
                    placeholder="Entrez le sujet de la réclamation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.sujet && touched.sujet}
                    value={values.sujet}
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.sujet}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="description">Description</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows="6"
                    placeholder="Entrez la description de la réclamation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.description && touched.description}
                    value={values.description}
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Envoyer
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
        <Col>
          <img
            src="https://png.pngtree.com/png-clipart/20230913/original/pngtree-claim-clipart-cartoon-character-working-on-an-insurance-policy-is-holding-png-image_11077164.png"
            alt=""
            style={{ width: "380px", height: "450px" }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Reclamations;
