import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "./Contexts/ThemeContext";
import store from "./Redux/store";
import { Provider } from "react-redux";

import "./index.css";
import "./CustomCSS/css/style.css"
import "./CustomCSS/css/owl.css"
import "./CustomCSS/css/jquery.fancybox.min.css"
import "./CustomCSS/css/animate.css"
import "./CustomCSS/css/color.css"
import "./CustomCSS/css/responsive.css"
import "./CustomCSS/css/iconflat.css" 
import "./CustomCSS/css/font-awesome-all.css" 

 

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <ThemeProvider>
        {" "}
        <App />
      </ThemeProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
