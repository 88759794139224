import React, { useState } from "react";
import { Container, Nav, Row, Col, Card, Button, Image } from "react-bootstrap";
import ProfileSecurity from "./ProfileSecurity";
import AccountDetails from "./AccountDetails";
import NotificationProfile from "./NotificationProfile";
import BanksAccounts from "./BanksAccounts";

const Profile = () => {
  const [profile, setProfile] = useState(true);
  const [security, setSecurity] = useState(false);
  const [notification, setNotification] = useState(false);
  const [banks, setBanks] = useState(false);

  const handleDisplayPage = (page) => {
    setProfile(page === "profile");
    setSecurity(page === "security");
    setNotification(page === "notification");
    setBanks(page === "banks");
  };
  return (
    <Container className="mt-4">
      {/* Account page navigation */}
      <Nav className="nav-borders">
        <Nav.Item>
          <Nav.Link
            onClick={() => handleDisplayPage("profile")}
            active={profile}
          >
            Profile
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            onClick={() => handleDisplayPage("security")}
            active={security}
          >
            Security
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            onClick={() => handleDisplayPage("notification")}
            active={notification}
          >
            Notifications
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link onClick={() => handleDisplayPage("banks")} active={banks}>
            Bank Accounts
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <hr className="mt-0 mb-4" />
      <Row>
        <Col xl={4}>
          {/* Profile picture card */}
          <Card className="mb-4 mb-xl-0">
            <Card.Header>Profile Picture</Card.Header>
            <Card.Body className="text-center">
              {/* Profile picture image */}
              <Image
                src="https://www.elitesingles.co.uk/wp-content/uploads/sites/59/2019/11/2b_en_articleslide_sm2-350x264.jpg"
                roundedCircle
                className="mb-2 img-round-profile"
              />
              {/* Profile picture help block */}
              <div className="small font-italic text-muted mb-4">
                JPG or PNG no larger than 5 MB
              </div>
              {/* Profile picture upload button */}
              <Button variant="primary" type="button">
                Upload new image
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {profile && <AccountDetails />}
        {security && <ProfileSecurity />}
        {notification && <NotificationProfile />}
        {banks && <BanksAccounts />}
      </Row>
    </Container>
  );
};

export default Profile;
