import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
// Function to dynamically load a script
const loadScript = (src, callback) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = false;  // Ensures the script loads in order
  script.onload = callback;  // Run callback after the script is loaded
  document.body.appendChild(script);
};


function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
      window.location.reload();

    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);


  useEffect(() => {
    // Load jQuery first
    loadScript('https://code.jquery.com/jquery-2.2.4.min.js', () => {
        loadScript('/js/script.js');
     });
}, []);
  return (
    <Container className="text-center" style={{ marginTop: "10%" }}>
      <Row>
        <Col>
          <h1 className="display-1">404</h1>
          <h2>Page Not Found</h2>
          <p className="lead">
            Sorry, the page you are looking for does not exist. You will be redirected to the homepage in a few seconds.
          </p>
          <Button variant="primary" onClick={() => navigate("/")}>
            Go to Homepage Now
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
