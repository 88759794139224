import React, { useState, useEffect } from "react";
import {Banks} from "../../Helpers/Banks";
import BankList from "../../Components/Banks";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ModalTermsConditions from "../../Components/ModalTermsConditions";
import { useNavigate } from "react-router-dom";

function SelectBank() {
  const [modalShow, setModalShow] = useState(false);
  const [rib, setRib] = useState();
  const [selectedBank, setSelectedBank] = useState(null);
  const navigate = useNavigate();

  const NavigateWithData = () => {
    navigate("/CheckDepositSteps", { state: { rib: rib } });
  };

  useEffect(() => {
    setModalShow(true);
  }, []);

  return (
    <>
      <ModalTermsConditions
        show={modalShow}
        onHide={() => setModalShow(false)}
        setRib={setRib}
      />
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        <h1 className="text-center my-4">Select your check's bank</h1>
        <Row xs={1} sm={2} md={3} className="justify-content-center g-3">
          {Banks.map((bankItem, key) => (
            <Col key={key} className="d-flex justify-content-center">
              <BankList
                image={bankItem.image}
                name={bankItem.name}
                isBlurred={selectedBank !== null && selectedBank !== key}
                onClick={() => setSelectedBank(key)}
                isSelected={selectedBank === key}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <Button variant="secondary" as={Link} to="/DepositCheck">
              Back
            </Button>
          </Col>
          <Col>
            <Button onClick={NavigateWithData}>Continue</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SelectBank;
