import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";

function Loader() {
  const loading = useSelector((state) => state.loader.loading);

  return (
    <div
      style={{
        display: loading ? "block" : "none",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "5665",
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        style={{ width: "50px", height: "50px" }}
      />
    </div>
  );
}

export default Loader;
