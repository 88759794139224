import React from "react";
import Navbar from "../Components/Navbar";
import { Container } from "react-bootstrap";
import { Outlet, Navigate } from "react-router-dom";

function InsideLayout() {
  const isAuthenticated = localStorage.getItem("access");

  return isAuthenticated ? (
    <>
      <Navbar />
      <Container fluid>
        <main>
          {" "}
          <Outlet />
        </main>
      </Container>
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default InsideLayout;
