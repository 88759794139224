import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { logoutUser } from "../Redux/Actions/userActionsCreators";
import { useTheme } from "../Contexts/ThemeContext";
import { Image } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "react-bootstrap/Badge";

import { requestPushNotification } from "../Redux/Actions/notificationActionsCreator";

const loadScript = (src, callback) => {
  const script = document.createElement("script");
  script.src = src;
  script.async = false; // Ensures the script loads in order
  script.onload = callback; // Run callback after the script is loaded
  document.body.appendChild(script);
};
function NavbarOff() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuth);
  const notifications = useSelector((state) => state.notification.all);
  const countNotifications = useSelector((state) => state.notification.count);
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  useEffect(() => {
    const cleanupWebSocket = dispatch(requestPushNotification());

    // Cleanup on component unmount
    return () => {
      if (typeof cleanupWebSocket === "function") {
        cleanupWebSocket(); // Call the cleanup function returned from requestPushNotification
      }
    };
  }, [dispatch]);

  const handleLogOut = () => {
    localStorage.removeItem("access");
    dispatch(logoutUser());
    navigate("/signin");
  };
  useEffect(() => {
    // Load jQuery first
    loadScript("https://code.jquery.com/jquery-2.2.4.min.js", () => {
      // After jQuery is loaded, load the other scripts
      loadScript("/js/popper.min.js");
      loadScript("/js/owl.js");
      loadScript("/js/wow.js");
      loadScript("/js/validation.js");
      loadScript("/js/TweenMax.min.js");
      loadScript("/js/appear.js");
      loadScript("/js/scrollbar.js");
      loadScript("/js/isotope.js");
      loadScript("/js/navbar.js");
      loadScript("/js/scroll_top.js");
      loadScript("/js/caroussel.js");
    });
  }, []);

  return (
    <div>
      <header class="main-header">
        <div class="header-top">
          <div class="top-inner">
            <ul class="left-info">
              <li>
                <i class="bi bi-telephone-fill"></i>{" "}
                <p>
                  <span>call: </span>
                  <a href="tel:+21671963453">+216 71 963 453</a>
                </p>
              </li>
              <li>
                <i class="bi bi-envelope-fill"></i>
                <p>
                  <span>Mail: </span>
                  <a href="mailto:commercial@abshore.com">
                    commercial@abshore.com
                  </a>
                </p>
              </li>
              <li>
                <i class="bi bi-geo-alt-fill"></i>{" "}
                <p>
                  <span>:</span> Banque de Tunisie - Tunis
                </p>
              </li>
            </ul>

            <div class="right-info">
              <ul class="list"></ul>
              <ul class="social-links">
                <li>
                  <a href="#">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-google-plus-g"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header-lower">
          <div class="outer-box clearfix">
            <div class="menu-area pull-left clearfix">
              <figure class="logo-box">
                <a href="#">
                  <img src="img/Logo_bt.png" alt="" />
                </a>
              </figure>
              <div class="mobile-nav-toggler">
                <i class="icon-bar"></i>
                <i class="icon-bar"></i>
                <i class="icon-bar"></i>
              </div>
              <nav class="main-menu navbar-expand-md navbar-light">
                <div
                  class="collapse navbar-collapse show clearfix"
                  id="navbarSupportedContent"
                >
                  <ul class="navigation clearfix">
                    <li>
                      <Nav.Link className="nav_ocd" id="login" as={Link} to="/">
                        {" "}
                        Accueil{" "}
                      </Nav.Link>
                    </li>
                    <li>
                      <a href="/#services">Services</a>
                    </li>
                    <li>
                      <a href="/#propos">A propos</a>
                    </li>
                    <li>
                      <a href="/#avantages">Avantages</a>
                    </li>
                    <li class="current dropdown">
                      <a href="/#solutions">Solutions</a>
                      <ul>
                        <li>
                          <a href="/#procedure-emis">Procédure d'émis Chèque</a>
                        </li>
                        <li class="dropdown">
                          <a href="/#procedure-depot">Pocedure dépot chèque</a>
                          <ul>
                            <li>
                              <a href="/#excellence">Excellence</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/#footer">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <ul class="menu-right-content pull-right clearfix d-flex gap-3 justify-content-center align-items-center">
              <li>
                {isAuthenticated ? (
                  <NavDropdown
                    title={
                      <div style={{ position: "relative" }}>
                        <i className="bi bi-bell"></i>
                        {notifications.length > 0 && (
                          <span
                            style={{
                              position: "absolute",
                              top: "-6px",
                              right: "-10px",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "50%",
                              padding: "2px 6px",
                              fontSize: "8px",
                              fontWeight: "bold",
                            }}
                          >
                            {notifications.length}
                          </span>
                        )}
                      </div>
                    }
                    id="basic-nav-dropdown"
                    className="rounded fs-5 notification-dropdown"
                    align={{ lg: "end" }}
                  >
                    <div className="notification-dropdown-content">
                      {notifications.length > 0 ? (
                        notifications.map((notification, index) => {
                          // Vérifier si la valeur de `time` est valide
                          let date = "N/A";
                          let time = "N/A";
                          if (notification.time) {
                            const dateTime = new Date(notification.time);
                            if (!isNaN(dateTime.getTime())) {
                              // Extraire la date et l'heure si la valeur est valide
                              date = dateTime.toISOString().split("T")[0];
                              time = dateTime.toTimeString().split(" ")[0];
                            }
                          }

                          return (
                            <NavDropdown.Item key={index}>
                              <i className="bi bi-magic"></i>
                              <Badge pill bg="secondary">
                                {notification.message} - {date} {time}
                              </Badge>
                            </NavDropdown.Item>
                          );
                        })
                      ) : (
                        <NavDropdown.Item disabled>
                          No new notifications
                        </NavDropdown.Item>
                      )}
                    </div>
                  </NavDropdown>
                ) : (
                  ""
                )}
              </li>

              <li>
                {isAuthenticated && (
                  <Nav.Link as={Link} to="/Profile">
                    <Image
                      className="img-round"
                      src="https://www.elitesingles.co.uk/wp-content/uploads/sites/59/2019/11/2b_en_articleslide_sm2-350x264.jpg"
                      alt="Profile"
                    />
                  </Nav.Link>
                )}
              </li>
              <li>
                <div class="language">
                  <div class="lang-btn">
                    <i class="bi bi-translate"></i>
                    <span class="txt">FR</span>
                    <span class="arrow fa fa-angle-down"></span>
                  </div>
                  <div class="lang-dropdown">
                    <ul>
                      <li>
                        <a href="#">Français</a>
                      </li>
                      <li>
                        <a href="#">Anglais</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                {isAuthenticated && (
                  <Button
                    className="theme-btn-one"
                    id="Button_react"
                    onClick={handleLogOut}
                  >
                    <i class="bi bi-box-arrow-left"></i>
                    Log Out
                  </Button>
                )}
                {!isAuthenticated && (
                  <Nav.Link
                    className="theme-btn-one"
                    id="login"
                    as={Link}
                    to="/SignIn"
                  >
                    Log In
                  </Nav.Link>
                )}
              </li>
            </ul>
          </div>
        </div>

        <div class="sticky-header">
          <div class="outer-box clearfix">
            <div class="menu-area pull-left clearfix">
              <figure class="logo-box" id="logo_nav">
                <a href="#">
                  <img src="img/Logo_bt.png" alt="" />
                </a>
              </figure>
              <nav class="main-menu clearfix">
                {/* <!--Keep This Empty / Menu will come through Javascript--> */}
              </nav>
            </div>

            <ul class="menu-right-content pull-right clearfix  d-flex gap-3 justify-content-center align-items-center">
              <li>
                {isAuthenticated ? (
                  <NavDropdown
                    title={
                      <div style={{ position: "relative" }}>
                        <i className="bi bi-bell"></i>
                        {notifications.length > 0 && (
                          <span
                            style={{
                              position: "absolute",
                              top: "-6px",
                              right: "-10px",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "50%",
                              padding: "2px 6px",
                              fontSize: "8px",
                              fontWeight: "bold",
                            }}
                          >
                            {countNotifications}
                          </span>
                        )}
                      </div>
                    }
                    id="basic-nav-dropdown"
                    className="rounded fs-5 notification-dropdown"
                    align={{ lg: "end" }}
                  >
                    <div className="notification-dropdown-content">
                      {countNotifications > 0 ? (
                        notifications.map((notification, index) => (
                          <NavDropdown.Item href="#action/3.1" key={index}>
                            <i className="bi bi-magic"></i>{" "}
                            {notification.message}
                          </NavDropdown.Item>
                        ))
                      ) : (
                        <NavDropdown.Item disabled>
                          No new notifications
                        </NavDropdown.Item>
                      )}
                    </div>
                  </NavDropdown>
                ) : (
                  ""
                )}
              </li>
              <li>
                {isAuthenticated && (
                  <Nav.Link as={Link} to="/Profile">
                    <Image
                      className="img-round"
                      src="https://www.elitesingles.co.uk/wp-content/uploads/sites/59/2019/11/2b_en_articleslide_sm2-350x264.jpg"
                      alt="Profile"
                    />
                  </Nav.Link>
                )}
              </li>
              <li>
                <div class="language">
                  <div class="lang-btn">
                    <i class="bi bi-translate"></i>
                    <span class="txt">FR</span>
                    <span class="arrow fa fa-angle-down"></span>
                  </div>
                  <div class="lang-dropdown">
                    <ul>
                      <li>
                        <a href="#">Français</a>
                      </li>
                      <li>
                        <a href="#">Anglais</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                {isAuthenticated && (
                  <Button
                    className="theme-btn-one"
                    id="Button_react"
                    onClick={handleLogOut}
                  >
                    <i class="bi bi-box-arrow-left"></i>
                    Log Out
                  </Button>
                )}
                {!isAuthenticated && (
                  <Nav.Link
                    className="theme-btn-one"
                    id="login"
                    as={Link}
                    to="/SignIn"
                  >
                    Log In
                  </Nav.Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn">
          <i class="fas fa-times"></i>
        </div>
        <nav class="menu-box">
          <div class="nav-logo">
            <a href="#">
              <img
                src="https://www.bt.com.tn/Themes/BT/Content/logo_bt.png"
                alt=""
                title=""
              />
            </a>
          </div>
          <div class="menu-outer"></div>
          <div class="contact-info">
            <h4>Contactez-nous</h4>
            <ul>
              <li>Banque BT Tunisie</li>
              <li>
                <a href="tel:+21671963453">+216 71 963 453</a>
              </li>
              <li>
                <a href="mailto:commercial@abshore.com">
                  commercial@abshore.com
                </a>
              </li>
            </ul>
          </div>
          <div class="social-links">
            <ul class="clearfix">
              <li>
                <a href="#">
                  <span class="fab fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="fab fa-facebook-square"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="fab fa-pinterest-p"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="fab fa-instagram"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="fab fa-youtube"></span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div class="progress-wrap">
        <i class="bi bi-arrow-up" id="top_scroll"></i>
        <svg
          class="progress-circle svg-content"
          style={{ width: "100%", height: "100%" }}
          viewBox="-1 -1 102 102"
          id="scroll_svg"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              stroke_dasharray: "307.919, 307.919",
              stroke_dashoffset: "136.248",
            }}
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default NavbarOff;
