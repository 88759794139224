import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import Instructions from "../../Components/Instructions";
import UploadCard from "../../Components/UploadCard";

const TakePicture = ({ title, face, screenshot, setScreenshot }) => {
  const [stream, setStream] = useState(null);
  const [webcamActive, setWebcamActive] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [videoWidth, setVideoWidth] = useState(
    window.innerWidth > 768 ? 600 : window.innerWidth
  );

  const handleStartWebcam = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(mediaStream);
      setWebcamActive(true);
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  const handleStopWebcam = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setStream(null);
      setWebcamActive(false);
    }
  };

  const handleCaptureScreenshot = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    if (videoRef.current) {
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL("image/png");
      setScreenshot(dataUrl);
    }
    handleStopWebcam();
  };

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setScreenshot(reader.result);
        console.log('reader.result',reader.result);
        console.log('file',file);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (webcamActive && videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }
  }, [webcamActive, stream]);

  useEffect(() => {
    return () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [stream]);

  useEffect(() => {
    const handleResize = () =>
      setVideoWidth(window.innerWidth > 768 ? 600 : window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "50px",
        gap: "20px",
      }}
    >
      <h1>{title}</h1>

      {webcamActive ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            border: "2px dashed #0D7CC3",
            height: "auto",
            width: "100%",
            maxWidth: "1295px",
            borderRadius: "5px",
            padding: "20px",
          }}
        >
          <Row>
            <Col lg={4}>
              <Instructions face={face} />
            </Col>
            <Col
              lg={8}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div
                className="video-cam"
                style={{
                  width: videoWidth,
                  height: "264px",
                  overflow: "hidden",
                  position: "relative",
                  marginTop: "15px",
                }}
              >
                <video
                  ref={videoRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  autoPlay
                />
              </div>
              <div
                style={{
                  width: "410px",
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                <Button
                  onClick={handleCaptureScreenshot}
                  style={{
                    width: "200px",
                    background: "#E68F36",
                    border: "#E68F36",
                  }}
                >
                  Take a Pic
                </Button>
                <Button
                  onClick={handleStopWebcam}
                  style={{
                    width: "200px",
                    background: "#0D7CC3",
                    border: "#0D7CC3",
                  }}
                >
                  Cancel
                </Button>
              </div>
              <canvas
                ref={canvasRef}
                width={videoWidth}
                height={264}
                style={{ display: "none" }}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          {screenshot ? (
            <div style={{ height: "670px" }}>
              <div style={{ border: "2px dashed #0D7CC3", padding: "10px" }}>
                <img
                  src={screenshot}
                  alt="Screenshot"
                  width={600}
                  height={264}
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <UploadCard
                handleStartWebcam={handleStartWebcam}
                handleFileUpload={handleFileUpload}
              />
            </div>
          ) : (
            <UploadCard
              handleStartWebcam={handleStartWebcam}
              handleFileUpload={handleFileUpload}
            />
          )}
        </Container>
      )}
    </div>
  );
};

export default TakePicture;
