import React from "react";
import { Col, Card, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePermission } from "../../CustomHook/usePermission";

function BanksAccounts() {
  const canDelete = usePermission("delete");
  const canEdit = usePermission("edit");
  const bankAccounts = useSelector((state) => state.user.accountsList);
  console.log("bankAccounts", bankAccounts);

  return (
    <Col xl={8}>
      {/* Account details card */}
      <Card>
        <Card.Header>Accounts List</Card.Header>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              posuere erat a ante.
            </p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Account Number</th>
                  <th>Banque</th>
                  <th>Agence</th>
                  {(canEdit || canDelete) && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {bankAccounts.map((bankAccount, index) => (
                  <tr key={index}>
                    <td>{bankAccount.numeroCompte}</td>
                    <td>{bankAccount.banque.nom_banque}</td>
                    <td>{bankAccount.agence.nom_agence}</td>
                    {(canEdit || canDelete) && (
                      <td>
                        {canEdit && (
                          <i
                            className="bi bi-pencil-fill"
                            style={{ color: "#FFD700" }}
                          ></i>
                        )}
                        {canDelete && (
                          <i
                            className="bi bi-x-circle-fill"
                            style={{ color: "#FF0000" }}
                          ></i>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </blockquote>
        </Card.Body>
        <Button as={Link} to="/CreateBankAccount">
          Add new bank account
        </Button>
      </Card>
    </Col>
  );
}

export default BanksAccounts;
