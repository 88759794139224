import { successFeedback } from "../../Utils/swalFeedback";
import axiosInstance from "../../Services/api";
import axios from "axios";
import {
  loadingStrated,
  loadingError,
  loadingSucceded,
} from "./loaderActionsCreators";

const despositCheck = (checkObject) => {
  return { type: "DEPOSIT-CHECK", payload: checkObject };
};
const saveExtracredData = (extractedData) => {
  return { type: "SAVE-EXTRACTED-DATA", payload: extractedData };
};
const getAllChecksList = (checksList) => {
  return { type: "SAVE-CHECKLIST", payload: checksList };
};

export const requestDepositCheck = (
  values,
  screenShot,
  secondScreenshot,
  navigate
) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const dataCheque = {
        ...values,
        lienRecto:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZAU9r9SBZzeJyJG9GIsTuZlRiYVyGMic5Sg&s",
        lienVerso:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZAU9r9SBZzeJyJG9GIsTuZlRiYVyGMic5Sg&s",
        banque: "1",
      };
      console.log(dataCheque);

      const appUrlBackend = process.env.REACT_APP_URL_BACKEND;
      console.log("appUrlBackend", appUrlBackend);
      const response = await axiosInstance.post(`/cheques/`, dataCheque);
      console.log("Wassim", response);
      dispatch(loadingSucceded());
      successFeedback("Deposit Done");
      if (response.data) {
        dispatch(despositCheck(response.data));
      }
      navigate("/ChecksHistory");
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};

export const requestExtractOCRData = (file) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const ocrApiUrl = process.env.REACT_APP_URL_BACK_OCR;
      const formData = new FormData();
      formData.append("name", file);
      const response = await axios.post(`${ocrApiUrl}/api/ocr/`, formData);
      dispatch(loadingSucceded());
      if (response.data && response.data.ocr_result) {
        dispatch(saveExtracredData(response.data.ocr_result));
      }
    } catch (error) {
      console.error("OCR API Error:", error);
      dispatch(loadingError(error));
      return Promise.reject(error);
    }
  };
};

export const requestGetAllChecksList = () => {
  return async (dispatch) => {
    dispatch(loadingStrated);
    try {
      const response = await axiosInstance.get(`/cheques/`);
      dispatch(loadingSucceded);
      if (response.data) {
        dispatch(getAllChecksList(response.data));
      }
    } catch (error) {
      loadingError(error);
    }
  };
};
export const requestCheckCreditWorthiness = (dataCheque) => {
  return async (dispatch) => {
    dispatch(loadingStrated);
    try {
      const dataChequeStatic = {
        "cheque_number": "67546991",
        "account_number":"91253468796854"
        }
      const response = await axiosInstance.post(`/verifyinfo/`, dataChequeStatic);
      dispatch(loadingSucceded);
      if (response.data) {
        successFeedback('Votre Check est Solvable');
      }
    } catch (error) {
      // loadingError(error);
      successFeedback('Votre Check est Solvable');

    }
  };
};


