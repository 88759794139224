const initialState = {
  isAuth: false,
  userInfo: null,
  access: null,
  refresh: null,
  role: "admin",
  permissions: ["delete"],
  accountsList: [],
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuth: true,
        userInfo: action.payload.user,
        refresh: action.payload.refresh,
        access: action.payload.access,
        role: "admin",
        permissions: ["edit", "delete"],
      };
    case "LOGOUT":
      return {
        ...state,
        isAuth: false,
        userInfo: null,
        token: null,
      };
    case "UPDATE":
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };
    case "EDIT-2FA":
      return {
        ...state,
        userInfo: { ...state.userInfo, is_two_factor_enabled: action.payload },
      };
    case "ADD-ACCOUNT":
      return {
        ...state,
        accountsList: [...state.accountsList, action.payload],
      };
    case "ALL-ACCOUNTS":
      return {
        ...state,
        accountsList: action.payload,
      };
    case "UPDATE-ACCESS":
      return {
        ...state,
        access: action.payload,
      };
    default:
      return { ...state };
  }
};
export default userReducer;
