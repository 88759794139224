import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Dropdown } from "react-bootstrap";
import pdf from "../Files/terms.pdf";

function ModalTermsConditions({ show, onHide, setRib }) {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { accountsList } = useSelector((state) => state.user);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Effect to set the initial selectedAccount when accountsList changes
  useEffect(() => {
    if (accountsList.length > 0) {
      setSelectedAccount(accountsList[0]);
    } else {
      setSelectedAccount(null); // Handle case when no accounts are available
    }
  }, [accountsList]);

  const goBack = () => {
    navigate(-1); // This will navigate to the previous path in the history
  };

  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="contained-modal-title-vcenter">
          Please Choose Your Bank Account
        </Modal.Title>
        <Form.Text as={Link} to="/CreateBankAccount">
          Add a new account
        </Form.Text>
      </Modal.Header>
      <Modal.Body className={`${isChecked ? "" : "blurred"}`}>
        <Row className="justify-content-center align-items-center">
          {selectedAccount ? ( // Conditional rendering based on selectedAccount
            <>
              <Col>
                <img
                  src="img/Logo_bt.png"
                  alt="Bank Logo"
                  className="img-fluid"
                  style={{ height: "80px" }}
                />
              </Col>
              <Col>
                <p className="mb-0 no-wrap">
                  <strong>Agence:</strong> {selectedAccount.agence.nom_agence}
                </p>
              </Col>
              <Col>
                <p className="mb-0 no-wrap">
                  <strong>RIB:</strong> {selectedAccount.numeroCompte}
                </p>
              </Col>
              <Col>
                <Dropdown align="end">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-banks"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu>
                    {accountsList.map((account, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setSelectedAccount(account);                          
                        }}
                      >
                        Numéro de compte: {account.numeroCompte}
                        <Badge
                          bg={
                            selectedAccount &&
                            account.agence.nom_agence ===
                              selectedAccount.agence.nom_agence
                              ? "info"
                              : "secondary"
                          }
                        >
                          Agence: {account.agence.nom_agence}
                        </Badge>
                        <Badge
                          bg={
                            selectedAccount &&
                            account.banque.nom_banque ===
                              selectedAccount.banque.nom_banque
                              ? "info"
                              : "secondary"
                          }
                        >
                          Banque: {account.banque.nom_banque}
                        </Badge>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </>
          ) : (
            <Col className="text-center">
              <p>No bank accounts available. Please add a new account.</p>
            </Col>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Row className="w-100 align-items-center justify-content-between">
          <Col className="d-flex align-items-center gap-2">
            <Form.Check
              className={!isChecked ? "blue-border" : ""}
              type="checkbox"
              aria-label="option 1"
              id="check"
              style={{ marginLeft: "10px" }}
              onChange={handleCheckBoxChange}
            />
            <p className="mb-0 ml-2">
              I Accept The{" "}
              <a href={pdf} download="Terms & Conditions">
                Terms & Conditions
              </a>
            </p>
          </Col>
          <Col className="d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={goBack}>
              Back
            </Button>
            <Button
              onClick={() => {
                if (selectedAccount) {
                  setRib(selectedAccount.numeroCompte);
                }
                onHide();
                // Assuming you want to handle rib somewhere else
              }}
              disabled={!isChecked || !selectedAccount}
            >
              Done
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalTermsConditions;
