import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requestValidateAccount } from "../../Redux/Actions/userActionsCreators";

import Alert from "react-bootstrap/Alert";
// Function to dynamically load a script
const loadScript = (src, callback) => {
  const script = document.createElement("script");
  script.src = src;
  script.async = false; // Ensures the script loads in order
  script.onload = callback; // Run callback after the script is loaded
  document.body.appendChild(script);
};

function AccountValidation() {
  const { id, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    loadScript("https://code.jquery.com/jquery-2.2.4.min.js", () => {
      loadScript("/js/script.js");
    });
  }, []);

  useEffect(() => {
    const validateAccount = async () => {
      if (!token) {
        setMessage("Account can't be activated");
        return;
      }
      dispatch(requestValidateAccount(token, id, setMessage, navigate));
    };

    validateAccount();
  }, [token, dispatch]);

  return (
    <div class="container">
      {message && (
        <div class="row justify-content-center mt-5">
          <div class="col-md-6">
            <div class="success shadow">
              <div class="card-header fs-3">{message}</div>
              <div class="card-body">
                <i class="success-icon bi bi-check-circle-fill"></i>
                <p class="mt-3">
                  Your account has been successfully activated. You can now
                  explore all our features and services.
                </p>
                <a href="#" class="btn btn-custom mt-4">
                  Go to Dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountValidation;
