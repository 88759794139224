import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../images/onlineBanking.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import sideLogo from "../images/card.png";
import * as formik from "formik";
import * as yup from "yup";
import { requestAddBankAccount } from "../Redux/Actions/userActionsCreators";
import axiosInstance from "../Services/api";

function AddBankAccount() {
  const [agences, setAgence] = useState([]);
  // Update your useEffect to properly use async function
  useEffect(() => {
    const fetchAgences = async () => {
      try {
        const response = await axiosInstance.get("/agence/");
        if (response.data) {
          setAgence(response.data);
        }
      } catch (error) {
        console.log("error");
      }
    };

    fetchAgences();
  }, []);
  const { Formik } = formik;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Schéma de validation avec Yup
  const schema = yup.object().shape({
    numero_compte: yup
      .string()
      .required("Account Number is required")
      .matches(/^\d+$/, "Account Number must be a number"),
      agence_id: yup.string().required("Sélectionné votre agence"),
  });

  const initialValues = {
    numero_compte: "",
    agence_id: "",
  };

  return (
    <div>
      <Container className="my-4">
        <Card
          style={{
            border: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.10) 5px 10px 10px, rgba(0, 0, 0, 0.10) 5px 1px 10px, rgba(0, 0, 0, 0.10) 0px 5px 5px, rgba(0, 0, 0, 0.16) 0px 5px 10px",
          }}
        >
          <Row className="g-2">
            <Col md="6" style={{ background: "whitesmoke" }}>
              <Card.Body className="d-flex flex-column">
                <h3 className="fw-normal pb-3" style={{ letterSpacing: "1px" }}>
                  Add Bank Account <i className="bi bi-bank"></i>
                </h3>
                <div className="d-flex flex-row mt-2">
                  <Image src={sideLogo} style={{ width: "150px" }} />
                </div>
                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => {
                    console.log("Submitted values:", values);
                    dispatch(requestAddBankAccount(values, navigate));
                  }}
                  initialValues={initialValues}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group
                        className="mb-4 text-start"
                        controlId="formAccountNumber"
                      >
                        <Form.Control
                          type="text"
                          name="numero_compte"
                          placeholder="Numèro compte Banquaire"
                          value={values.numero_compte}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.numero_compte && !!errors.numero_compte
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.numero_compte}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="mb-4 text-start"
                        controlId="formSWIFT"
                      >
                        <Form.Select
                          type="text"
                          name="agence_id"
                          value={values.agence_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.agence_id && !!errors.agence_id}
                        >
                          <option value="" disabled>
                            Select an Agence
                          </option>
                          {agences.map((agence) => (
                            <option
                              key={agence.id_agence}
                              value={agence.id_agence}
                            >
                              {agence.nom_agence}
                            </option>
                          ))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                          {errors.agence_id}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="d-flex justify-content-evenly">
                        <Button
                          style={{ background: "#E68F36", border: "#E68F36" }}
                          type="submit"
                        >
                          Add Bank Account
                        </Button>
                        <Button
                          style={{ background: "#E68F36", border: "#E68F36" }}
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Retour
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p
                  className="mb-5 pb-lg-2"
                  style={{ color: "#393f81", marginTop: "20px" }}
                >
                  Learn more about the <Link to="#">Privacy Policy</Link> and{" "}
                  <Link to="#">Cookie Policy</Link>.
                </p>
              </Card.Body>
            </Col>
            <Col md="6">
              <Card.Img
                src={logo}
                alt="login form"
                className="rounded-start w-100"
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default AddBankAccount;
