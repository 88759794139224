const initialState = {
  all: [],
};
const reclamationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD-ALL-RECLAMATION":
      return { ...state, all: action.payload };
    case "ADD-ONE-RECLAMATION":
      return { ...state, all: [...state.all, action.payload] };
    default:
      return state;
  }
};
export default reclamationReducer;
