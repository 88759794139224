import { successFeedback } from "../../Utils/swalFeedback";
import axiosInstance from "../../Services/api";
import axios from "axios";
import {
  loadingStrated,
  loadingError,
  loadingSucceded,
} from "./loaderActionsCreators";

const loginUser = (user, access, refresh) => {
  return { type: "LOGIN", payload: { user, access, refresh } };
};
const logoutUser = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("user");
  localStorage.removeItem("refresh");
  return { type: "LOGOUT" };
};

const editUser = (userData) => {
  return { type: "UPDATE", payload: userData };
};
const toggle2FA = (enable_2fa) => {
  return { type: "EDIT-2FA", payload: enable_2fa };
};
const addBankAccount = (dataAccount) => {
  return { type: "ADD-ACCOUNT", payload: dataAccount };
};
const getAllBankAccounts = (dataAccounts) => {
  return { type: "ALL-ACCOUNTS", payload: dataAccounts };
};
const updateAccessToken = (access) => {
  return { type: "UPDATE-ACCESS", payload: access };
};

const requestLoginUser = (
  email,
  password,
  recaptchaValue,
  setShowConfirmationModal,
  navigate
) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const dataLogin = {
        username: email,
        password,
        recaptchaValue,
      };
      const response = await axiosInstance.post(`/login/`, dataLogin);
      dispatch(loadingSucceded());
      if (
        response &&
        response.data &&
        response.data.message &&
        !response.data.access &&
        !response.data.user
      ) {
        setShowConfirmationModal(true);
        successFeedback(response.data.message);
      }

      if (
        response &&
        response.data &&
        response.data.access &&
        response.data.user
      ) {
        const { user, access, refresh } = response.data;
        localStorage.setItem("access", access);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("refresh", refresh);
        document.cookie = `access=${access}; path=/`;
        dispatch(loginUser(user, access, refresh));
        successFeedback(`${response.data.message}, Welcome ${user.username}`);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};
const requesVerifyEFA = (
  email,
  password,
  confirmationCode,
  navigate,
  setShowConfirmationModal
) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const dataLogin = {
        username: email,
        password,
        otp: confirmationCode,
      };
      const response = await axiosInstance.post(`/verifyotp/`, dataLogin);
      dispatch(loadingSucceded());
      const { user, access, refresh } = response.data;
      if (response && response.data && user && access) {
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", refresh);
        localStorage.setItem("user", JSON.stringify(user));
      }
      if (response.data.message && user) {
        successFeedback(`${response.data.message}, Welcome ${user.username}`);
      }
      setShowConfirmationModal(false);
      dispatch(loginUser(user, access, refresh));
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};

const requestToggle2FA = (value) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const enable_2fa = value === "0" ? true : false;
      const dataToogle2FA = {
        enable_2fa,
      };

      const response = await axiosInstance.put(`/toggle-2fa/`, dataToogle2FA);
      dispatch(loadingSucceded());
      if (response.data && response.data.message) {
        dispatch(toggle2FA(enable_2fa));
        successFeedback(response.data.message);
      }
    } catch (error) {
      dispatch(loadingError(error));
    }
  };
};
const requestValidateAccount = (token, id, setMessage, navigate) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const appUrlBackend = process.env.REACT_APP_URL_BACKEND;

      const response = await axios.post(
        `${appUrlBackend}/api/validate-account/${id}/${token}`
      );
      dispatch(loadingSucceded());
      if (response.data && response.data.message) {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
      setMessage(error.response.data.message);
    } finally {
      // Navigate with replace: true to prevent navigating back to the validation page
      setTimeout(() => {
        navigate("/", { replace: true }); // Redirect to home page
      }, 4000); // 3 seconds delay
    }
  };
};

const requestResetPassword = (id, token, password, navigate) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const response = await axiosInstance.post(`/reset-password/`, {
        password,
        uidb64: id,
        token,
      });
      dispatch(loadingSucceded());
      if (response.data && response.data.message) {
        successFeedback(response.data.message);
      }
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};
const requestForgetPassword = (email, navigate) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const response = await axiosInstance.post(`/forget-password/`, { email });
      dispatch(loadingSucceded());
      if (response.data && response.data.message) {
        successFeedback(response.data.message);
      }
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};
const requestGetAllBankAccounts = () => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const response = await axiosInstance.get(`/profile_bancaire/`);
      dispatch(loadingSucceded());
      if (response.data) {
        dispatch(getAllBankAccounts(response.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};
const requestAddBankAccount = (values, navigate) => {
  return async (dispatch) => {
    dispatch(loadingStrated());
    try {
      const response = await axiosInstance.post(`/profile_bancaire/`, values);
      dispatch(loadingSucceded());
      if (response.data && response.data.message) {
        successFeedback(response.data.message);
      }
      dispatch(addBankAccount(response.data.detail))
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};
const requestRegisterRD = (values, navigate) => {
  return async (dispatch) => {
    const {
      nom,
      prenom,
      cin,
      date_naissance,
      civilite,
      password,
      email,
      username,
    } = values;
    dispatch(loadingStrated());
    try {
      const response = await axiosInstance.post(`/responsable_depots/`, {
        nom,
        prenom,
        cin,
        date_naissance,
        civilite,
        password,
        email,
        username,
      });
      dispatch(loadingSucceded());
      if (response.data && response.data.message) {
        successFeedback(response.data.message);
      }
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};
const requestRegisterMorale = (values, navigate) => {
  return async (dispatch) => {
    const {
      nom_societe,
      matricule_fiscale,
      telephone,
      password,
      email,
      username,
    } = values;
    dispatch(loadingStrated());
    try {
      const response = await axiosInstance.post(`/ownermorale/`, {
        nom_societe,
        matricule_fiscale,
        telephone,
        password,
        email,
        username,
      });
      dispatch(loadingSucceded());
      if (response.data && response.data.message) {
        successFeedback(response.data.message);
      }
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(loadingError(error));
    }
  };
};

export {
  loginUser,
  logoutUser,
  editUser,
  requestLoginUser,
  requestValidateAccount,
  requesVerifyEFA,
  requestToggle2FA,
  requestResetPassword,
  requestForgetPassword,
  requestRegisterRD,
  requestRegisterMorale,
  requestGetAllBankAccounts,
  requestAddBankAccount,
  updateAccessToken,
};
