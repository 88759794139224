import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { requestGetAllChecksList } from "./Redux/Actions/checkActionsCreators";
import { requestGetAllReclamation } from "./Redux/Actions/reclamationsActionsCreators";
import { requestGetAllBankAccounts } from "./Redux/Actions/userActionsCreators";
import { requestGetAllNotifications } from "./Redux/Actions/notificationActionsCreator";
import InsideLayout from "./Layouts/InsideLayout";
import OutsideLayout from "./Layouts/OutsideLayout";
import CommonLayout from "./Layouts/CommonLayout";

import Dashboard from "./pages/Home";
import HowToDeposit from "./pages/HowToDeposit";
import DepositCheckHome from "./pages/DepositCheck/DepositCheckHome";
import AboutUs from "./pages/AboutUs";
import SignUp from "./pages/Authentication/SignUp";
import SignIn from "./pages/Authentication/SignIn";
import Contact from "./pages/Contact";
import AddBankCard from "./pages/AddBankCard";
import SelectBank from "./pages/DepositCheck/SelectBank";
import CheckDepositSteps from "./pages/DepositCheck/CheckDepositSteps";
import ScrollToTop from "./Components/ScrollToTop";
import Loader from "./Components/Loader";
import Profile from "./pages/Profile/Profile";
import ProfileSecurity from "./pages/Profile/ProfileSecurity";
import NotFound from "./pages/NotFound";
import ChecksHistory from "./pages/DepositCheck/ChecksHistory";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import ProtectedRoute from "./pages/Roles/ProtectedRoutes";
import Reclamations from "./pages/Reclamations/Reclamation";
import ReclamationList from "./pages/Reclamations/ReclamationList";
import AccountValidation from "./pages/Authentication/AccountValidation";
import ResetPassword from "./pages/Authentication/ResetPassword";
import { loginUser } from "./Redux/Actions/userActionsCreators";
import { useTheme } from "./Contexts/ThemeContext";

import "./CustomCSS/DarkLight.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  const { theme, toggleTheme } = useTheme();
  const dispatch = useDispatch();
  const access = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (!access && !refresh) {
      return;
    }
    dispatch(requestGetAllChecksList());
    dispatch(requestGetAllReclamation());
    dispatch(requestGetAllBankAccounts());
    dispatch(requestGetAllNotifications());
  }, [dispatch]);

  if (access && user && refresh) {
    dispatch(loginUser(user, access, refresh));
  }

  return (
    <div className={`App ${theme}`} style={{ height: "100%" }}>
      <Loader />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<CommonLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/HowToDeposit" element={<HowToDeposit />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/DepositCheck" element={<DepositCheckHome />} />
            <Route
              path="/validate-account/:id/:token"
              element={<AccountValidation />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route element={<InsideLayout />}>
            <Route path="/CreateBankAccount" element={<AddBankCard />} />
            <Route path="/SelectBank" element={<SelectBank />} />
            <Route path="/CheckDepositSteps" element={<CheckDepositSteps />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/ProfileSecurity" element={<ProfileSecurity />} />
            <Route path="ChecksHistory" element={<ChecksHistory />} />
            <Route path="ReclamationsList" element={<ReclamationList />} />
            <Route
              path="/Reclamations"
              element={
                <ProtectedRoute allowedRoles={["admin"]}>
                  <Reclamations />
                </ProtectedRoute>
              }
            ></Route>
          </Route>
          <Route element={<OutsideLayout />}>
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/SignIn" element={<SignIn />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route
              path="/ResetPassword/:id/:token"
              element={<ResetPassword />}
            />{" "}
            {/* Reset Password Page */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
