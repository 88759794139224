const initialState = {
  checksList: [],
  OcrExrtactedData: {
    account_holder_name: "",
    amount_in_numbers: "",
    amount_in_letters: "",
    account_number: "",
    cheque_number: "",
    date: "",
    location: "",
    payee: "",
    phone: "",
  },
};

const checkReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DEPOSIT-CHECK":
      return { ...state, checksList: [...state.checksList, action.payload] };
    case "SAVE-EXTRACTED-DATA":
      return {
        ...state,
        OcrExrtactedData: { ...action.payload },
      };
    case "SAVE-CHECKLIST":
      return {
        ...state,
        checksList: action.payload,
      };
    default:
      return state;
  }
};
export default checkReducer;
